import { OAuthUserResponse, TCaptchaProps, TCommonAuthModalProps } from '@/features/auth/EntryForm';
import { EAuthSteps } from '@/features/auth/EntryModal';
import { RECAPTCHA_ACTIONS, RECAPTCHA_MODES } from '@/features/recaptcha';
import { screenSizes } from '@/libs/utils/screensize';
import { useScreenSize } from '@/libs/utils/useScreensize';
import { AuthType } from '@/root/libs/api/auth';
import { UserApiErrorV1 } from '@/root/libs/api/eagle-api-wrapper/api-gateway';
import { authUserViaGoogle, GoogleLoginDTO } from '@/root/libs/api/login';
import { resendEmail } from '@/root/libs/api/register';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { AuthResponsePayloadDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/auth-response.dto';
import { ResponseError } from '@/root/types/ResponseError';
const RENDER_GOOGLE_BUTTON = 'RENDER_GOOGLE_BUTTON';
const GOOGLE_AUTH_EVENT_TYPE = 'auth-google-user';
type TProps = {
  authType: AuthType;
  changeEmail: (email: string) => void;
  changeAuthType: (authType: AuthType) => void;
  onLoginSuccess: () => void;
  changeEmailConfirmState: (state: 'success' | 'error' | null) => void;
  currentStep: EAuthSteps;
  changeCurrentStep: (step: EAuthSteps) => void;
} & Omit<TCommonAuthModalProps, 'loading' | 'errors'> & TCaptchaProps;
export const GoogleAuthButton = ({
  changeEmail,
  changeAuthType,
  onLoginSuccess,
  changeEmailConfirmState,
  changeErrors,
  changeErrorCode,
  currentStep,
  changeCurrentStep,
  changeLoading,
  captchaToken,
  captchaMode,
  getCaptchaToken,
  clearCaptchaData,
  domainWithProtocol
}: TProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isButtonLoad, setIsButtonLoad] = useState<boolean>(false);
  const authButtonUrl = process.env.GOOGLE_AUTH_BUTTON_URL;
  const screenSize = useScreenSize();
  const [socialUser, setSocialUser] = useState<OAuthUserResponse | null>(null);
  useEffect(() => {
    const onLoginMessage = (message: MessageEvent) => {
      const event = message.data;
      if (!event) {
        return;
      }
      switch (event.action) {
        case GOOGLE_AUTH_EVENT_TYPE:
          setSocialUser(event.user);
          changeAuthType(AuthType.Google);
          changeErrors([]);
          changeErrorCode(null);
          if (!event.user.email) {
            changeEmailConfirmState('error');
            return;
          }
          changeEmailConfirmState(null);
          changeLoading(true);
          changeCurrentStep(EAuthSteps.GOOGLE_AUTH);
          getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
          break;
        default:
          break;
      }
    };
    window.addEventListener('message', onLoginMessage);
    return () => window.removeEventListener('message', onLoginMessage);
  }, [changeAuthType, changeCurrentStep, changeEmailConfirmState, changeErrorCode, changeErrors, changeLoading, getCaptchaToken]);
  const getLoginData = useCallback((user: OAuthUserResponse, captchaToken: string, captchaMode: RECAPTCHA_MODES | undefined, domainWithProtocol: string) => {
    const loginData: GoogleLoginDTO & {
      authProviderAppId: string;
    } = {
      token: user.access_token,
      email: user.email ?? null,
      emailRetrievalConsent: false,
      registrationPlaceUrl: domainWithProtocol,
      registrationAppId: new URL(domainWithProtocol).hostname,
      authProviderAppId: process.env.DEFAULT_GOOGLE_APP_ID as string,
      //TODO: switch to dynamic googleAppId from arena config
      captchaToken,
      ...(captchaMode ? {
        captchaMode
      } : {})
    };
    return loginData;
  }, []);
  useEffect(() => {
    if (iframeRef?.current && isButtonLoad) {
      iframeRef.current.contentWindow?.postMessage({
        type: RENDER_GOOGLE_BUTTON,
        payload: {
          innerWidth: window.innerWidth,
          googleAppId: process.env.DEFAULT_GOOGLE_APP_ID //TODO: switch to dynamic googleAppId from arena config
        }
      }, authButtonUrl);
    }
  }, [authButtonUrl, isButtonLoad]);
  useEffect(() => {
    if (captchaToken && currentStep === EAuthSteps.GOOGLE_AUTH) {
      (async () => {
        const loginData = getLoginData(socialUser as OAuthUserResponse, captchaToken as string, captchaMode, domainWithProtocol) as GoogleLoginDTO;
        const res = await fetchInternalRoute<ResponseError | AuthResponsePayloadDto>(INTERNAL_ROUTES.AUTH_BY_GOOGLE, {
          method: 'POST',
          body: JSON.stringify(loginData)
        });
        // const res = await authUserViaGoogle(loginData);
        changeLoading(false);
        if (res && 'errorCode' in res) {
          changeErrors(res.message as string[]);
          changeErrorCode(res.errorCode);
          if (res.errorCode === UserApiErrorV1.EmailDoesNotSpecified) {
            changeEmailConfirmState('error');
          }
          if (res.errorCode === UserApiErrorV1.UserNotConfirmedError) {
            changeEmail(loginData.email);
            changeLoading(true);
            changeCurrentStep(EAuthSteps.SIGN_IN_UP_RESEND_EMAIL);
            getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
          }
          return;
        }
        onLoginSuccess();
        clearCaptchaData();
      })();
    }
  }, [captchaMode, captchaToken, changeCurrentStep, changeEmail, changeEmailConfirmState, changeErrorCode, changeErrors, changeLoading, clearCaptchaData, domainWithProtocol, getCaptchaToken, getLoginData, onLoginSuccess, socialUser, currentStep]);
  useEffect(() => {
    if (captchaToken && currentStep === EAuthSteps.SIGN_IN_UP_RESEND_EMAIL) {
      (async () => {
        const res = await fetchInternalRoute<ResponseError | void>(INTERNAL_ROUTES.RESEND_EMAIL, {
          method: 'POST',
          body: JSON.stringify({
            email: (socialUser as OAuthUserResponse).email,
            authType: AuthType.Google,
            resendConfirmationFormUrl: domainWithProtocol,
            captchaToken,
            ...(captchaMode ? {
              captchaMode
            } : {})
          })
        });
        // const res = await resendEmail({
        // 	email: (socialUser as OAuthUserResponse).email,
        // 	authType: AuthType.Google,
        // 	resendConfirmationFormUrl: domainWithProtocol,
        // 	captchaToken,
        // 	...(captchaMode ? { captchaMode } : {}),
        // });
        if (res && 'errorCode' in res) {
          changeErrors(res.message as string[]);
          changeErrorCode(res.errorCode);
          return;
        }
        clearCaptchaData();
        changeCurrentStep(EAuthSteps.EMAIL_SENT_FORM);
      })();
    }
  }, [captchaMode, captchaToken, changeCurrentStep, changeErrorCode, changeErrors, clearCaptchaData, currentStep, domainWithProtocol, socialUser]);
  return <iframe ref={iframeRef} onLoad={() => setIsButtonLoad(true)} src={authButtonUrl} title='google button iframe' className={classNames({
    [styles.googleAuthButton]: isButtonLoad
  })} height='40px' width={screenSize === screenSizes.xxs ? '360px' : '400px'} data-sentry-component="GoogleAuthButton" data-sentry-source-file="index.tsx" />;
};