'use client';

import React, { useState } from 'react';
import { EBirthdayErrors } from '@/root/actions/profile/updateUserBirthday.action';
import style from '@/components/Profile/Components/ProfileFields/style.module.css';
import { ProfileText } from '@/components/Profile/Components/ProfileText/ProfileText';
import classNames from 'classnames';
import Image from 'next/image';
import { ProfileError } from '@/components/Profile/Components/ProfileError/ProfileError';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import { ResponseError } from '@/root/types/ResponseError';
export type ProfileBirthdayProps = {
  birthday: string;
};
export const Birthday = ({
  birthday
}: ProfileBirthdayProps) => {
  const [value, setValue] = useState<string>(birthday ?? '');
  const [error, setError] = useState<null | string>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const doUpdate = (ev: any) => {
    const value = ev.currentTarget.value;
    const error = checkBirthdayErrors(value);
    if (!error) {
      setValue(value);
      setDisabled(true);
      fetchInternalRoute<UserProfileDTO | ResponseError>(INTERNAL_ROUTES.UPDATE_BIRTHDAY, {
        method: 'PATCH',
        body: JSON.stringify({
          birthday: value
        })
      }).then(updatedProfile => {
        if ('errorCode' in updatedProfile) {
          setError(updatedProfile.message as string);
          return;
        }
        updatedProfile.birthday !== value && setValue(updatedProfile.birthday);
        setError(null);
      }).finally(() => {
        setDisabled(false);
      });
    } else {
      setError(error);
    }
  };
  return <div className={style.FieldBlock} data-sentry-component="Birthday" data-sentry-source-file="Birthday.tsx">
			<label className={style.Field} data-testid='profile-birthday-field'>
				<ProfileText text={'Birthday'} isBold={true} addClasses={[style.FieldLabel]} data-sentry-element="ProfileText" data-sentry-source-file="Birthday.tsx" />
				<input name='birthday' type='date' placeholder='Birthday' className={classNames(style.FieldInput)} value={value} disabled={disabled} onChange={doUpdate} />
				<div className={style.FieldInputIcon} style={{
        pointerEvents: 'none'
      }}>
					<Image className={style.FieldInputIconImage} src='/assets/time/calendar.svg' alt='input-icon' width={18} height={18} data-sentry-element="Image" data-sentry-source-file="Birthday.tsx" />
				</div>
			</label>
			<ProfileError error={error} data-sentry-element="ProfileError" data-sentry-source-file="Birthday.tsx" />
		</div>;
};
export function checkBirthdayErrors(newVal: any) {
  return !newVal ? EBirthdayErrors.REQUIRED : isNaN(new Date(newVal).getTime()) ? EBirthdayErrors.INVALID : new Date().getTime() - new Date(newVal).getTime() < 18 * 365 * 24 * 60 * 60 * 1000 ? EBirthdayErrors.YOUNG : null;
}