export type TScreen = {
	'xxs': '592px';

	'xs': '810px';

	'sm': '1024px';

	'md': '1240px';

	'lg': '1366px';

	'xl': '1600px';

	'2xl': '1920px';
};

export enum EScreenSize {
	xxs = 'xxs',
	xs = 'xs',
	sm = 'sm',
	md = 'md',
	lg = 'lg',
}

export const screenSizes = {
	'xxs': '592px',
	'xs': '810px',
	'sm': '1024px',
	'md': '1240px',
	'lg': '1366px',
	'xl': '1600px',
	'2xl': '1920px',
};
