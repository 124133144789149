import { profileActionUpdateUser } from '@/root/libs/api/profile';
import { z } from 'zod';
import { profileSchemaCheck } from '@/libs/utils/profileSchemaCheck';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import { ResponseError } from '@/types/ResponseError';
import { TProfileAvatarFormState } from '@/components/Profile/Components/ProfileAvatarSelection/ProfileAvatarSelection';
import { logger } from '@/libs/utils/logger';

export enum EAvatarErrors {
	REQUIRED = 'No avatar selected',
	FALLBACK = 'Something went wrong',
}

export const updateUserAvatar = async (
	prevState: TProfileAvatarFormState,
	formData: FormData,
): Promise<TProfileAvatarFormState> => {
	const data = Object.fromEntries(formData);
	const avatarSelected: string = (data?.avatarSelected ?? '') as string;
	const schema = z.string().min(1, { message: EAvatarErrors.REQUIRED });
	let result: TProfileAvatarFormState = { ...prevState, avatarSelected };
	const check = profileSchemaCheck(schema, avatarSelected);
	if (check) {
		result = { ...prevState, error: check as string };
		return result;
	}

	const res: UserProfileDTO | ResponseError = await profileActionUpdateUser({
		avatar: avatarSelected,
	});
	if ('errorCode' in res) {
		logger.error(res);
		result = { ...prevState, error: (res.message ?? EAvatarErrors.FALLBACK) as string };
		return result;
	}
	result = { ...prevState, avatarSelected: res.avatar, error: '' };
	return result;
};
