'use client';

import React from 'react';
import styles from './styles.module.css';
import { AuthText } from '@/components/Auth/AuthText/AuthText';
export type AuthErrorProps = {
  error: string | null;
  stylePassed?: any;
};
export const AuthError = ({
  error,
  stylePassed
}: AuthErrorProps) => {
  return error && <AuthText addClasses={[styles.ErrorMsg]} text={error} stylePassed={stylePassed} testId={'error'} />;
};