import { Category } from '@/libs/api/arena-data';
import { CUSTOM_BEHAVIOUR_REGIONS } from '@/libs/enums/CustomBehaviorRegions';
import { GameState } from '@/libs/enums/GameState';

export const ALL_CATEGORY = {
	name: 'See All',
	id: -1,
	slug: '',
	image: null,
	carousels: [],
	arena_tags: [],
	seo: null,
	title: null,
	description: null,
} as Category;
export const ALPHABETICAL_SORT_LABEL = 'Alphabetically';
export const DEFAULT_ROOT_CATEGORIES_SLUG = 'categories';

export const PLATFORM_TYPE_HEADER_NAME = 'ark__platformtype';
export const ARENA_HEADER_NAME = 'ark__environment';
export const ARENA_SENTRY_TAG_NAME = 'evo.arena';
export const DEVICE_TYPE_SENTRY_TAG_NAME = 'evo.device_type';
export const USER_AGENT_SENTRY_TAG_NAME = 'evo.user_agent';
export const FETCH_DESTINATION_SENTRY_TAG_NAME = 'evo.fetch_destination';
export const IS_PREFETCH_SENTRY_TAG_NAME = 'evo.is_prefetch';
export const ENDPOINT_HEADER_NAME = 'x-evo-endpoint';
export const ENDPOINT_SENTRY_TAG_NAME = 'evo.endpoint';
export const ENDPOINT_PARAMETERS_HEADER_NAME = 'x-evo-endpoint-parameters';
export const ENDPOINT_PARAMETERS_SENTRY_TAG_NAME = 'evo.endpoint_parameters';
export const PAGE_TYPE_TAG_NAME = 'evo.page_type';
export const COUNTRY_CODE_TAG_NAME = 'geo.country_code';
export const COUNTRY_CODE_HEADER_NAME = 'X-Ark-Request-Origin-Country';

export const toExcludeQSP = [
	PLATFORM_TYPE_HEADER_NAME,
	'utm',
	ARENA_HEADER_NAME,
	'ark__nextjs_next_url',
	'ark__next_router_prefetch',
	'ark__next_router_state_tree',
	'BAD_QSP',
];

export enum EmailCodes {
	REGISTER_CONFIRMATION_CODE = 'account_confirmation_code',
	RESET_PASSWORD_CODE = 'request_reset_password_code',
	EMAIL_CHANGE_CONFIRMATION_CODE = 'email_change_confirmation_code',
}

export const DEFAULT_LOCALE = 'en';
export const INGAME_GAME_STATES = [GameState.GAME, GameState.REWARD, GameState.INTERSTITIAL];

// CMP Constants

const CHINA_REGIONS = new Set([CUSTOM_BEHAVIOUR_REGIONS.CN, CUSTOM_BEHAVIOUR_REGIONS.HK, CUSTOM_BEHAVIOUR_REGIONS.MO]);
export const NO_CMP_AND_ADS_REGIONS = new Set([...CHINA_REGIONS]);
export const US_REGIONS = new Set([
	CUSTOM_BEHAVIOUR_REGIONS.US,
	CUSTOM_BEHAVIOUR_REGIONS.AS,
	CUSTOM_BEHAVIOUR_REGIONS.GU,
	CUSTOM_BEHAVIOUR_REGIONS.MP,
	CUSTOM_BEHAVIOUR_REGIONS.PR,
	CUSTOM_BEHAVIOUR_REGIONS.UM,
	CUSTOM_BEHAVIOUR_REGIONS.VI,
]);
export const GDPR_REGIONS = new Set<string>([
	CUSTOM_BEHAVIOUR_REGIONS.AT,
	CUSTOM_BEHAVIOUR_REGIONS.AX,
	CUSTOM_BEHAVIOUR_REGIONS.BE,
	CUSTOM_BEHAVIOUR_REGIONS.BG,
	CUSTOM_BEHAVIOUR_REGIONS.CY,
	CUSTOM_BEHAVIOUR_REGIONS.CZ,
	CUSTOM_BEHAVIOUR_REGIONS.DE,
	CUSTOM_BEHAVIOUR_REGIONS.DK,
	CUSTOM_BEHAVIOUR_REGIONS.EE,
	CUSTOM_BEHAVIOUR_REGIONS.EL,
	CUSTOM_BEHAVIOUR_REGIONS.ES,
	CUSTOM_BEHAVIOUR_REGIONS.EU,
	CUSTOM_BEHAVIOUR_REGIONS.FI,
	CUSTOM_BEHAVIOUR_REGIONS.FR,
	CUSTOM_BEHAVIOUR_REGIONS.GB,
	CUSTOM_BEHAVIOUR_REGIONS.GF,
	CUSTOM_BEHAVIOUR_REGIONS.GI,
	CUSTOM_BEHAVIOUR_REGIONS.GP,
	CUSTOM_BEHAVIOUR_REGIONS.GR,
	CUSTOM_BEHAVIOUR_REGIONS.HR,
	CUSTOM_BEHAVIOUR_REGIONS.HU,
	CUSTOM_BEHAVIOUR_REGIONS.IE,
	CUSTOM_BEHAVIOUR_REGIONS.IS,
	CUSTOM_BEHAVIOUR_REGIONS.IT,
	CUSTOM_BEHAVIOUR_REGIONS.LI,
	CUSTOM_BEHAVIOUR_REGIONS.LT,
	CUSTOM_BEHAVIOUR_REGIONS.LU,
	CUSTOM_BEHAVIOUR_REGIONS.LV,
	CUSTOM_BEHAVIOUR_REGIONS.ME,
	CUSTOM_BEHAVIOUR_REGIONS.MF,
	CUSTOM_BEHAVIOUR_REGIONS.MQ,
	CUSTOM_BEHAVIOUR_REGIONS.MT,
	CUSTOM_BEHAVIOUR_REGIONS.NL,
	CUSTOM_BEHAVIOUR_REGIONS.NO,
	CUSTOM_BEHAVIOUR_REGIONS.PL,
	CUSTOM_BEHAVIOUR_REGIONS.PT,
	CUSTOM_BEHAVIOUR_REGIONS.RE,
	CUSTOM_BEHAVIOUR_REGIONS.RO,
	CUSTOM_BEHAVIOUR_REGIONS.SE,
	CUSTOM_BEHAVIOUR_REGIONS.SI,
	CUSTOM_BEHAVIOUR_REGIONS.SK,
	CUSTOM_BEHAVIOUR_REGIONS.YT,
	CUSTOM_BEHAVIOUR_REGIONS.UK,
	CUSTOM_BEHAVIOUR_REGIONS.CA,
	CUSTOM_BEHAVIOUR_REGIONS.BR,
]);

export const EXTERNAL_SCRIPTS_NAMES: Record<string, string> = {
	QUANTCAST: 'quantcast',
	MicrosoftOneDSCustomTelemetry: 'Microsoft1DS',
};

export const THIRD_PARTY_ADS_OPT_OUT_COOKIE_NAME = '3PAdsOptOut';

export const DEFAULT_AVATAR = {
	filename: 'avatar_blank.png',
	analyticsKey: '0',
	premium: false,
	description: 'A default avatar that is a silhouette.',
};

export const DEFAULT_AVATAR_BACKGROUND = `rgba(0, 66, 187)`;

export const qaSearchParams = {
	PROFILE_ERROR: 'arkTestProfileError',
};

export const GUARDED_ROUTES = ['profile'];
