import { profileActionUpdateUser } from '@/root/libs/api/profile';
import { z } from 'zod';
import { EBirthdayErrors } from '@/root/actions/profile/updateUserBirthday.action';
import { FeatureType, ResponseError } from '@/types/ResponseError';
import { profileSchemaCheck } from '@/libs/utils/profileSchemaCheck';
import { UserApiErrorV1 } from '@/root/libs/api/eagle-api-wrapper/api-gateway';

export const minLength = 3;
export const maxLength = 19;

export enum EUsernameErrors {
	EMPTY = "Username can't be empty",
	SHORT = `User name should be minimum ${minLength} letters long`,
	LONG = `User name should be maximum ${maxLength} letters long`,
	FORMAT = `User name should be from ${minLength} to ${maxLength} characters long and contain only letters and digits`,
}

export const updateUserName = async (name: string) => {
	const schema = z
		.string()
		.min(1, { message: EBirthdayErrors.REQUIRED })
		.min(minLength, { message: EUsernameErrors.SHORT })
		.max(maxLength, { message: EUsernameErrors.LONG })
		.refine((username) => /^[a-zA-Z][a-zA-Z0-9_-]{2,19}$/.test(username), {
			message: EUsernameErrors.FORMAT,
		});
	const check = profileSchemaCheck(schema, name);
	if (check) {
		return {
			message: check,
			errorCode: UserApiErrorV1.InvalidRequestData,
			statusCode: 400,
			feature: FeatureType.PROFILE,
		} as ResponseError;
	}
	const res = await profileActionUpdateUser({
		name,
	});
	return res;
};
