import { AuthResponseDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/auth-response.dto';
import { fetchJson } from '../../utils';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import { UserUpdateRequestDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-update.dto';
import { UserPublicProfileDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-public-profile.dto';
import { UserCheckEmail } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-check-email.dto';
import { UserProfileForSocialDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile-for-social.dto';
import { UserCheckName } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-check-name.dto';
import { ChangeEmailRequestDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/change-email-request.dto';
import { ChangeEmailConfirmDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/change-email-confirm.dto';

export class UserManagmentApi {
	private server?: URL;

	// private sessionStorage?: SessionStorage;

	setServer(server: URL) {
		if (!server) {
			throw new Error('Please, provide server Url');
		}
		this.server = server;
	}

	// setSessionStorage(storage: SessionStorage) {
	// 	this.sessionStorage = storage;
	// }

	private saveCredential(data: AuthResponseDTO) {
		// if (this.sessionStorage) {
		// 	this.sessionStorage.update(SessionStorageOptions.fromV1(data));
		// }
	}

	private makeRequest(
		method: string,
		url: string,
		body: Record<any, any> | null = null,
		token: string = '',
		captchaToken: string = '',
		captchaMode?: string,
	) {
		if (!this.server) {
			throw new Error('No server url provided');
		}

		const headers = new Headers({ 'Content-Type': 'application/json' });

		if (token) {
			headers.append('Authorization', `Bearer ${token}`);
		}

		if (captchaToken) {
			headers.append('recaptcha', captchaToken);
		}

		if (captchaMode) {
			headers.append('recaptcha-mode', captchaMode);
		}

		return fetchJson(this.server.toString() + url, {
			method,
			headers,
			...(body
				? {
						body: JSON.stringify({ ...body }),
					}
				: {}),
		});
	}

	private doPost(url: string, body: Record<any, any>, token: string = '') {
		const captchaToken = body.captchaToken || '';
		const captchaMode = body.captchaMode || undefined;

		delete body.captchaToken;
		delete body.captchaMode;
		return this.makeRequest('POST', url, body, token, captchaToken, captchaMode);
	}

	private doPatch(url: string, body: Record<any, any>, token: string = '') {
		return this.makeRequest('PATCH', url, body, token);
	}

	private doDelete(url: string, body: Record<any, any> | null, token: string = '') {
		return this.makeRequest('DELETE', url, body, token);
	}

	private doGet(url: string, token: string = '') {
		return this.makeRequest('GET', url, null, token);
	}

	getUserProfile(token: string): Promise<UserProfileDTO> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }
		// return this.sessionStorage.getToken().then((token) => {
		return this.doGet('user', token);
		// });
	}

	getPublicUserProfile(uid: string, token: string): Promise<UserPublicProfileDto> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }

		// return this.sessionStorage.getToken().then((token) => {
		return this.doGet(`user/${uid}`, token);
		// });
	}

	updateUser(data: UserUpdateRequestDTO, token?: string): Promise<UserProfileDTO> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }

		// return this.sessionStorage.getToken().then((token) => {
		return this.doPatch('user', { ...data }, token);
		// });
	}

	// /** @deprecate Please use checkUserEmail instead */
	// getUserByEmail(email: string): Promise<UserProfileForSocialDto> {
	// 	if (!this.sessionStorage) {
	// 		return Promise.reject('Please, provide session storage');
	// 	}
	// 	return this.doGet(`user/email/${email}`);
	// }

	generateUserName(): Promise<string> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }
		return this.doGet(`user/generate`);
	}

	checkUserEmail(data: UserCheckEmail): Promise<UserProfileForSocialDto> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }
		return this.doPost(`user/check-email`, { ...data });
	}

	checkUserName(data: UserCheckName): Promise<{ result: boolean }> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }
		return this.doPost('user/check-name', { ...data });
	}

	changeEmailRequest(data: ChangeEmailRequestDTO, token: string): Promise<void> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }

		// return this.sessionStorage.getToken().then((token) => {
		return this.doPost(
			'user/email/request',
			{
				...data,
				changeEmailFormUrl: data.changeEmailFormUrl ? data.changeEmailFormUrl.toString() : undefined,
			},
			token,
		);
		// });
	}

	changeEmailConfirm(data: ChangeEmailConfirmDTO): Promise<void> {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }
		return this.doPost('user/email/confirm', { ...data });
	}

	softDelete(token: string) {
		return this.doDelete('user', null, token);
	}

	gdprDelete(token: string) {
		// if (!this.sessionStorage) {
		// 	return Promise.reject('Please, provide session storage');
		// }

		// return this.sessionStorage.getToken().then((token) => {
		return this.doDelete('user/gdpr', null, token);
		// });
	}
}
