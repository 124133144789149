'use client';

import React from 'react';
import { ProfileText } from '@/components/Profile/Components/ProfileText/ProfileText';
import style from './style.module.css';
import classNames from 'classnames';
export type ProfileSectionFieldProps = {
  value: string;
};
export const Email = ({
  value
}: ProfileSectionFieldProps) => {
  return <label className={style.Field} data-testid='profile-email' data-sentry-component="Email" data-sentry-source-file="Email.tsx">
			<ProfileText text='Email' isBold={true} addClasses={[style.FieldLabel]} data-sentry-element="ProfileText" data-sentry-source-file="Email.tsx" />
			<input className={classNames(style.FieldInput)} type='text' placeholder='myemail@example.com' value={value} disabled={true} />
		</label>;
};