'use client';

import React from 'react';
import classNames from 'classnames';
import style from './style.module.css';
export type ProfileTextProps = {
  text: string;
  addClasses?: string[];
  isBold?: boolean;
  color?: 'black' | 'red' | 'gray';
  stylePassed?: any;
  testId?: string;
};
export const ProfileText = (props: ProfileTextProps) => {
  const text = props.text ?? '';
  const isBold = props.isBold ?? false;
  const color = props.color;
  const addClasses = props.addClasses ?? [];
  const stylePassed = props.stylePassed ?? {};
  return <p className={classNames(style.Text, {
    [style.__TextBlack]: !color || color === 'black',
    [style.__TextRed]: color === 'red',
    [style.__TextGray]: color === 'gray',
    [style.__TextBold]: isBold
  }, ...addClasses)} style={{
    color,
    ...stylePassed
  }} data-testid={`profile-text${props.testId ? `-${props.testId}` : ''}`} title={text} data-sentry-component="ProfileText" data-sentry-source-file="ProfileText.tsx">
			{text}
		</p>;
};