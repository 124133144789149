'use client';

import { Root, Submit } from '@radix-ui/react-form';
import formStyles from '../form.styles.module.css';
import modalStyles from '@/components/Modal/styles.module.css';
import styles from './styles.module.css';
import classNames from 'classnames';
import { ContactSupportBlock } from '@/components/Auth/ContactSupportBlock/ContactSupportBlock';
import { Description, Title } from '@radix-ui/react-dialog';
import { ContinueButton } from '@/components/Auth/ContinueButton/ContinueButton';
import { EAuthSteps } from '../EntryModal';
export type TResetPasswordSuccessFormProps = {
  loading: boolean;
  changeErrors: (errors: string[]) => void;
  changeErrorCode: (errorCode: number | null) => void;
  changeCurrentStep: (step: EAuthSteps) => void;
};
export const ResetPasswordSuccessForm = ({
  changeErrors,
  changeErrorCode,
  changeCurrentStep,
  loading
}: TResetPasswordSuccessFormProps) => {
  const resetPasswordSuccessFormAction = () => {
    changeErrors([]);
    changeErrorCode(null);
    changeCurrentStep(EAuthSteps.SIGN_IN_UP_EMAIL);
  };
  return <>
			<Title className={classNames(modalStyles.Title, styles.ResetPasswordSuccessTitle)} data-sentry-element="Title" data-sentry-source-file="index.tsx">Success</Title>
			<Description className={classNames(modalStyles.DescriptionPassword, styles.ResetPasswordSuccessDescription)} data-sentry-element="Description" data-sentry-source-file="index.tsx">
				Your password has been reset.
			</Description>

			<Description className={classNames(modalStyles.DescriptionPassword, styles.ResetPasswordSuccessDescription)} data-sentry-element="Description" data-sentry-source-file="index.tsx">
				Use it the next time you sign in​.
			</Description>
			<Root className={classNames(formStyles.Root, styles.ResetPasswordSuccessForm)} action={resetPasswordSuccessFormAction} data-sentry-element="Root" data-sentry-source-file="index.tsx">
				<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
					<ContinueButton loading={loading} text='Sign in' data-sentry-element="ContinueButton" data-sentry-source-file="index.tsx" />
				</Submit>
			</Root>
			<ContactSupportBlock data-sentry-element="ContactSupportBlock" data-sentry-source-file="index.tsx" />
		</>;
};