import { StaticImage } from '@/components/StaticImage';
import { ESpinnerSize } from './model';
import styles from './styles.module.css';
type TProps = {
  size: ESpinnerSize;
};
export const Spinner = (props: TProps) => {
  const getSpinnerPx = () => {
    switch (props.size) {
      case ESpinnerSize.SMALL:
        return 24;
      case ESpinnerSize.MEDIUM:
        return 32;
      case ESpinnerSize.LARGE:
        return 40;
      default:
        return 24;
    }
  };
  return <StaticImage className={styles.Spinner} src='/assets/spinner-icon.svg' alt='Spinner' width={getSpinnerPx()} height={getSpinnerPx()} data-sentry-element="StaticImage" data-sentry-component="Spinner" data-sentry-source-file="index.tsx" />;
};