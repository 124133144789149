'use client';

import React from 'react';
import { ProfileText } from '@/components/Profile/Components/ProfileText/ProfileText';
import style from './style.module.css';
import { TAvatarsLists } from '@/components/Profile';
import { ProfileAvatar } from '@/components/Profile/Components/ProfileAvatar/ProfileAvatar';
export type ProfileBlockTitleProps = {
  name: string;
  email: string;
  avatar: string;
  avatars: TAvatarsLists;
};
export const ProfileAsideBlock = ({
  avatar,
  avatars,
  name,
  email
}: ProfileBlockTitleProps) => {
  return <aside className={style.Aside} data-testid={`profile-aside-block`} data-sentry-component="ProfileAsideBlock" data-sentry-source-file="ProfileAsideBlock.tsx">
			<section className={style.AsideUserdata}>
				<ProfileAvatar avatar={avatar} size={48} avatars={avatars} data-sentry-element="ProfileAvatar" data-sentry-source-file="ProfileAsideBlock.tsx" />
				<div className={style.AsideUserdataTexts}>
					<ProfileText text={name} isBold={true} addClasses={[style.__TextAsideUsername]} data-sentry-element="ProfileText" data-sentry-source-file="ProfileAsideBlock.tsx" />
					<ProfileText text={email} addClasses={[style.__TextAsideEmail]} data-sentry-element="ProfileText" data-sentry-source-file="ProfileAsideBlock.tsx" />
				</div>
			</section>
			<ProfileText text='My Profile' addClasses={[style.__TextAsideMyProfile]} data-sentry-element="ProfileText" data-sentry-source-file="ProfileAsideBlock.tsx" />
		</aside>;
};