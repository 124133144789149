'use client';

import React, { useContext } from 'react';
import { ProfileText } from '@/components/Profile/Components/ProfileText/ProfileText';
import style from '@/components/Profile/Components/ProfileEmailConfirm/style.module.css';
import classNames from 'classnames';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { AuthType } from '@/libs/api/auth';
import { Email } from '@/components/Profile/Components/ProfileFields/Email';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { ResponseError } from '@/root/types/ResponseError';
import { AnalyticsContext } from '@/features/arena-data/view';
export type ProfileEmailProps = {
  email: string;
  isEmailConfirmed: boolean;
};
export const ProfileEmailConfirm = ({
  email,
  isEmailConfirmed
}: ProfileEmailProps) => {
  const {
    AITracks
  } = useContext(AnalyticsContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const doResendEmail = () => {
    setIsLoading(true);
    fetchInternalRoute<ResponseError | void>(INTERNAL_ROUTES.RESEND_EMAIL, {
      method: 'POST',
      body: JSON.stringify({
        email,
        authType: AuthType.EmailPassword
      })
    }).then(() => {
      setIsLoading(false);
      AITracks.profileResendConfirmationEmail();
    }).catch(() => setIsLoading(false));
  };
  return isEmailConfirmed ? <Email value={email} data-sentry-element="Email" data-sentry-component="ProfileEmailConfirm" data-sentry-source-file="ProfileEmailConfirm.tsx" /> : <>
			<Email value={email} data-sentry-element="Email" data-sentry-source-file="ProfileEmailConfirm.tsx" />
			<div className={classNames(style.EmailResendBlock, {
      [style.EmailResendDisabled]: isLoading
    })} data-testid={`profile-email-resend`}>
				<ProfileText text={`We've sent an email to verify your profile.`} color={'red'} data-sentry-element="ProfileText" data-sentry-source-file="ProfileEmailConfirm.tsx" />
				<ProfileText text={`Didn't receive it? Check your spam or resend email.`} data-sentry-element="ProfileText" data-sentry-source-file="ProfileEmailConfirm.tsx" />
				<ButtonCallToAction text={'Resend'} size='medium' styling={'outline'} addClass={style.__btnEmailResend} onClick={doResendEmail} testId={`profile-button`} data-sentry-element="ButtonCallToAction" data-sentry-source-file="ProfileEmailConfirm.tsx" />
			</div>
		</>;
};