'use client';

import { Root, Submit } from '@radix-ui/react-form';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { TCaptchaProps, TCommonAuthModalProps } from '../EntryForm';
import classNames from 'classnames';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
import { EAuthSteps } from '../EntryModal';
import formStyles from '../form.styles.module.css';
import modalStyles from '@/components/Modal/styles.module.css';
import styles from './styles.module.css';
import { Description, Title } from '@radix-ui/react-dialog';
import { ContinueButton } from '@/components/Auth/ContinueButton/ContinueButton';
import { PasswordField } from '@/components/Auth/PasswordField/PasswordField';
import { useCallback, useEffect, useState } from 'react';
import { RECAPTCHA_ACTIONS } from '@/features/recaptcha';
import { UserApiErrorV1 } from '@/root/libs/api/eagle-api-wrapper/api-gateway';
import { ResponseError } from '@/root/types/ResponseError';
import { resendEmail } from '@/root/libs/api/register';
import { AuthType } from '@/root/libs/api/auth';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
export type TSignInPasswordFormProps = {
  currentStep: EAuthSteps;
  changeCurrentStep: (step: EAuthSteps) => void;
  email: string;
  onLoginSuccess: () => void;
} & TCommonAuthModalProps & TCaptchaProps;
export const SignInPasswordForm = ({
  email,
  errors,
  changeErrors,
  changeErrorCode,
  currentStep,
  changeCurrentStep,
  loading,
  changeLoading,
  onLoginSuccess,
  captchaToken,
  captchaMode,
  getCaptchaToken,
  clearCaptchaData,
  domainWithProtocol
}: TSignInPasswordFormProps) => {
  const [password, setPassword] = useState<string>('');
  const errorCallback = useCallback((res: ResponseError) => {
    const userIsNotConfirmedError = res.errorCode === UserApiErrorV1.UserNotConfirmedError;
    const emailCodeIsInvalid = res.errorCode === UserApiErrorV1.UserEmailConfirmationCodeIsInvalid;
    const invalidCredentials = res.errorCode === UserApiErrorV1.EmailOrPasswordIsIncorrect;
    const isLowScoreCaptcha = res.errorCode === UserApiErrorV1.CaptchaScoreIsTooLow;
    const isInvalidCaptcha = res.errorCode === UserApiErrorV1.CaptchaValidationFailed;
    if (userIsNotConfirmedError || emailCodeIsInvalid) {
      clearCaptchaData();
      changeCurrentStep(EAuthSteps.RESEND_CONFIRMATION_EMAIL);
      changeLoading(true);
      getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
      return;
    }
    if (isInvalidCaptcha) {
      changeErrors(['Invalid captcha. Please try again.']);
      changeErrorCode(res.errorCode);
      return;
    }
    if (isLowScoreCaptcha) {
      changeErrorCode(res.errorCode);
      return;
    }
    if (invalidCredentials) {
      changeErrors(['Invalid email or password. Please re-enter your password or update your email address​.']);
      changeErrorCode(res.errorCode);
      return;
    }
    //default fallback
    changeLoading(false);
    changeErrors(res.message as string[]);
    changeErrorCode(res.errorCode);
  }, [changeCurrentStep, changeErrorCode, changeErrors, changeLoading, clearCaptchaData, getCaptchaToken]);
  const signInPasswordFormAction = (formData: FormData) => {
    changeErrors([]);
    const data = Object.fromEntries(formData);
    const password = data.password as string;
    setPassword(password);
    changeLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
  };
  useEffect(() => {
    if (captchaToken && currentStep === EAuthSteps.SIGN_IN_PASSWORD) {
      (async () => {
        const res = await fetchInternalRoute<UserProfileDTO | ResponseError>(INTERNAL_ROUTES.AUTH_BY_EMAIL, {
          body: JSON.stringify({
            email,
            password,
            captchaToken,
            registrationPlaceUrl: domainWithProtocol,
            ...(captchaMode ? {
              captchaMode
            } : {})
          }),
          method: 'POST'
        });
        if (res && 'errorCode' in res) {
          errorCallback(res);
          return;
        }
        changeLoading(false);
        onLoginSuccess();
        clearCaptchaData();
      })();
    }
  }, [errorCallback, captchaToken, changeLoading, clearCaptchaData, currentStep, changeCurrentStep, email, onLoginSuccess, domainWithProtocol, captchaMode, changeErrors, changeErrorCode, password]);
  useEffect(() => {
    if (captchaToken && currentStep === EAuthSteps.RESEND_CONFIRMATION_EMAIL) {
      (async () => {
        const res = await fetchInternalRoute<void | ResponseError>(INTERNAL_ROUTES.RESEND_EMAIL, {
          method: 'POST',
          body: JSON.stringify({
            email,
            authType: AuthType.EmailPassword,
            resendConfirmationFormUrl: domainWithProtocol,
            captchaToken,
            ...(captchaMode ? {
              captchaMode
            } : {})
          })
        });
        // const res = await resendEmail({
        // 	email,
        // 	authType: AuthType.EmailPassword,
        // 	resendConfirmationFormUrl: domainWithProtocol,
        // 	captchaToken,
        // 	...(captchaMode ? { captchaMode } : {}),
        // });
        changeLoading(false);
        if (res && 'errorCode' in res) {
          changeErrors(res.message as string[]);
          changeErrorCode(res.errorCode);
          return;
        }
        clearCaptchaData();
        changeCurrentStep(EAuthSteps.EMAIL_SENT_FORM);
      })();
    }
  }, [email, changeLoading, captchaMode, captchaToken, changeCurrentStep, changeErrorCode, changeErrors, clearCaptchaData, currentStep, domainWithProtocol]);
  return <>
			<Title className={classNames(modalStyles.Title, styles.SignInPasswordTitle)} data-sentry-element="Title" data-sentry-source-file="index.tsx">Welcome back</Title>
			<Description className={classNames(modalStyles.DescriptionPassword, styles.SignInPasswordDescription)} data-sentry-element="Description" data-sentry-source-file="index.tsx">
				Ready to play? <br />
				Sign in with <span className={modalStyles.strong}>{email}</span>
			</Description>
			<Root className={classNames(formStyles.Root, styles.SignInPasswordForm)} onSubmit={event => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      signInPasswordFormAction(data);
    }} data-sentry-element="Root" data-sentry-source-file="index.tsx">
				<PasswordField invalid={Boolean(errors.length)} name='password' withRequirements={false} data-sentry-element="PasswordField" data-sentry-source-file="index.tsx" />
				<div className={styles.ForgotPasswordButtonWrapper}>
					<ButtonCallToAction text='Forgot password?' styling='text' onClick={() => changeCurrentStep(EAuthSteps.FORGOT_PASSWORD)} data-sentry-element="ButtonCallToAction" data-sentry-source-file="index.tsx" />
				</div>
				<AuthErrorList errors={errors} data-sentry-element="AuthErrorList" data-sentry-source-file="index.tsx" />
				<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
					<ContinueButton loading={loading} text='Continue' data-sentry-element="ContinueButton" data-sentry-source-file="index.tsx" />
				</Submit>
			</Root>
		</>;
};