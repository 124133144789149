import { PasswordField } from '@/components/Auth/PasswordField/PasswordField';
import { Root, Submit } from '@radix-ui/react-form';
import { useEffect, useState } from 'react';
import styles from './styles.module.css';
import formStyles from '../form.styles.module.css';
import modalStyles from '@/components/Modal/styles.module.css';
import classNames from 'classnames';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
import { Description, Title } from '@radix-ui/react-dialog';
import { ContinueButton } from '@/components/Auth/ContinueButton/ContinueButton';
import { RECAPTCHA_ACTIONS } from '@/features/recaptcha';
import { EAuthSteps } from '../EntryModal';
import { TCommonAuthModalProps, TCaptchaProps } from '../EntryForm';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { ResponseError } from '@/root/types/ResponseError';
export type TResetPasswordFormProps = {
  changeCurrentStep: (step: EAuthSteps) => void;
  resetPasswordRecoveryCode: string;
} & TCommonAuthModalProps & TCaptchaProps;
export const ResetPasswordForm = ({
  errors,
  loading,
  changeErrors,
  changeErrorCode,
  changeCurrentStep,
  changeLoading,
  captchaToken,
  captchaMode,
  getCaptchaToken,
  clearCaptchaData,
  domainWithProtocol,
  resetPasswordRecoveryCode
}: TResetPasswordFormProps) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPassTouched, setIsPassTouched] = useState(false);
  const [isConfirmPassTouched, setIsConfirmPassTouched] = useState(false);
  const [arePassesEqual, setArePassesEqual] = useState<boolean | null>(null);
  useEffect(() => {
    if (isPassTouched && isConfirmPassTouched) {
      setArePassesEqual(password === confirmPassword);
    }
  }, [isPassTouched, isConfirmPassTouched, password, confirmPassword]);
  const resetPasswordFormAction = () => {
    changeErrors([]);
    changeLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.PASSWORD_RESET);
  };
  const PasswordEqualNotification = <div className={classNames(styles.ErrorNotification, {
    [styles.visible]: arePassesEqual === false
  })}>
			Password does not match. Please try again.
		</div>;
  useEffect(() => {
    if (captchaToken) {
      (async () => {
        const res = await fetchInternalRoute<ResponseError | void>(INTERNAL_ROUTES.CHANGE_PASSWORD_BY_CODE, {
          method: 'POST',
          body: JSON.stringify({
            newPassword: password,
            resetCode: resetPasswordRecoveryCode,
            captchaToken,
            ...(captchaMode ? {
              captchaMode
            } : {})
          })
        });
        // const res = await changePasswordByRecoveryCode({
        // 	newPassword: password,
        // 	resetCode: resetPasswordRecoveryCode,
        // 	captchaToken,
        // 	...(captchaMode ? { captchaMode } : {}),
        // });

        changeLoading(false);
        if (res && 'errorCode' in res) {
          changeErrors(res.message as string[]);
          changeErrorCode(res.errorCode);
          return;
        }
        changeCurrentStep(EAuthSteps.RESET_PASSWORD_FORM_SUCCESS);
        clearCaptchaData();
      })();
    }
  }, [captchaToken, changeLoading, clearCaptchaData, changeCurrentStep, domainWithProtocol, captchaMode, changeErrors, changeErrorCode, password, resetPasswordRecoveryCode]);
  const handlePassUpdate = (value: string) => {
    setPassword(value);
    setIsPassTouched(true);
  };
  const handleConfirmPassUpdate = (value: string) => {
    setConfirmPassword(value);
    setIsConfirmPassTouched(true);
  };
  return <>
			<Title className={classNames(modalStyles.Title, styles.ResetPasswordTitle)} data-sentry-element="Title" data-sentry-source-file="index.tsx">Choose your password</Title>
			<Description className={classNames(modalStyles.DescriptionPassword, styles.ResetPasswordDescription)} data-sentry-element="Description" data-sentry-source-file="index.tsx">
				Create a new password to regain access to your account.
			</Description>
			<Root className={classNames(formStyles.Root, styles.ResetPasswordForm)} onSubmit={event => {
      event.preventDefault();
      if (!arePassesEqual) return;
      resetPasswordFormAction();
    }} data-sentry-element="Root" data-sentry-source-file="index.tsx">
				<PasswordField name='newPassword' onUpdate={handlePassUpdate} withRequirements={true} invalid={Boolean(errors.length) || arePassesEqual === false} data-sentry-element="PasswordField" data-sentry-source-file="index.tsx" />
				<PasswordField name='confirmPassword' label='Repeat password' onUpdate={handleConfirmPassUpdate} withRequirements={false} notification={PasswordEqualNotification} invalid={Boolean(errors.length) || arePassesEqual === false} data-sentry-element="PasswordField" data-sentry-source-file="index.tsx" />
				<AuthErrorList errors={errors} data-sentry-element="AuthErrorList" data-sentry-source-file="index.tsx" />
				<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
					<ContinueButton loading={loading} text='Continue' data-sentry-element="ContinueButton" data-sentry-source-file="index.tsx" />
				</Submit>
			</Root>
		</>;
};