'use client';

import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';
import { ProfileAvatar } from '@/components/Profile/Components/ProfileAvatar/ProfileAvatar';
import { LocalizedLink } from '@/components/Link';
import { TProfileRoute } from '@/components/TopBar/UserDropdown';
import { StaticImage } from '@/components/StaticImage';
export enum DropdownType {
  User = 'user',
  Sort = 'sort',
}
export const Dropdown = ({
  items,
  onUpdate,
  type
}: any) => {
  const detailsElement = useRef<HTMLDetailsElement>(null),
    [selected, setSelected] = useState(items?.[0]),
    [displayBackdrop, setDisplayBackdrop] = useState<'initial' | 'none'>('none');
  function toggleDropdown(to: boolean) {
    if (detailsElement.current) {
      detailsElement.current.open = to;
    }
  }
  function onToggle() {
    const newDisplay = detailsElement.current?.open ? 'initial' : 'none';
    setTimeout(() => {
      setDisplayBackdrop(newDisplay);
    }, newDisplay === 'initial' ? 0 : 200);
  }
  function onKeyDown(e: any) {
    if (e.key === 'Escape') {
      toggleDropdown(false);
    }
  }
  function onBlur(e: any) {
    const {
      relatedTarget
    } = e;
    if (!relatedTarget || relatedTarget.dataset?.dropdown === undefined) {
      toggleDropdown(false);
    }
  }
  function selectItem(e: any) {
    if (e.code && !['Enter', 'Space'].includes(e.code)) {
      return;
    }
    const value = e.target.innerHTML;
    setSelected(value);
    onUpdate?.(value);
    toggleDropdown(false);
  }
  useEffect(() => {
    setSelected(items?.[0]);
  }, [items]);
  return <details ref={detailsElement} className={classNames(styles.toggle, {
    [styles.user]: type === DropdownType.User
  })} onToggle={onToggle} onBlur={onBlur} onKeyDown={onKeyDown} style={{
    '--display-backdrop': displayBackdrop
  } as React.CSSProperties} data-sentry-component="Dropdown" data-sentry-source-file="index.tsx">
			{type === DropdownType.Sort && <summary className={styles.selected} data-dropdown>
					<span>Sort by: {selected}</span>
					<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M6 9L12 15L18 9' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</summary>}

			{type === DropdownType.User && <summary className={styles.selected} data-dropdown>
					<ProfileAvatar size={40} isEditable={false} />
					<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M6 9L12 15L18 9' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</summary>}

			<div className={styles.content}>
				<div className={styles.list}>
					{items.map((i: TProfileRoute | string, index: number) => type === DropdownType.User ? <p key={(i as TProfileRoute).route} className={classNames(styles.item, styles.dropdownItemWithIcon)} onMouseDown={selectItem} onTouchStart={selectItem} onKeyDown={selectItem}>
								{(i as TProfileRoute).iconPath ? <StaticImage src={(i as TProfileRoute).iconPath} width={20} height={20} alt={(i as TProfileRoute).route} /> : null}
								{(i as TProfileRoute).label}
							</p> : <button key={index} type='button' className={styles.item} data-dropdown onMouseDown={selectItem} onTouchStart={selectItem} onKeyDown={selectItem}>
								{i as string}
							</button>)}
				</div>
			</div>
		</details>;
};