'use client';

import { Root, Field, Label, Submit } from '@radix-ui/react-form';
import { Root as CheckboxRoot, Indicator as CheckboxIndicator } from '@radix-ui/react-checkbox';
import { TCaptchaProps, TCommonAuthModalProps } from '../EntryForm';
import classNames from 'classnames';
import { StaticImage } from '@/components/StaticImage';
import formStyles from '../form.styles.module.css';
import modalStyles from '@/components/Modal/styles.module.css';
import styles from './styles.module.css';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
import { PasswordField } from '@/components/Auth/PasswordField/PasswordField';
import { Description, Title } from '@radix-ui/react-dialog';
import { ContinueButton } from '@/components/Auth/ContinueButton/ContinueButton';
import { RECAPTCHA_ACTIONS } from '@/features/recaptcha';
import { useEffect, useState } from 'react';
import { EAuthSteps } from '../EntryModal';
import { UserApiErrorV1 } from '@/root/libs/api/eagle-api-wrapper/api-gateway';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { ResponseError } from '@/root/types/ResponseError';
import { RegisterResponseDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/register-response.dto';
export type TSignUpPasswordFormProps = {
  email: string;
  changeCurrentStep: (step: EAuthSteps) => void;
} & TCommonAuthModalProps & TCaptchaProps;
export const SignUpPasswordForm = ({
  email,
  errors,
  loading,
  changeErrors,
  changeErrorCode,
  changeLoading,
  captchaToken,
  getCaptchaToken,
  captchaMode,
  domainWithProtocol,
  changeCurrentStep,
  clearCaptchaData
}: TSignUpPasswordFormProps) => {
  const [password, setPassword] = useState('');
  const [emailRetrievalConsent, setEmailRetrievalConsent] = useState(false);
  const signUpPasswordFormAction = (formData: FormData) => {
    changeErrors([]);
    changeErrorCode(null);
    const data = Object.fromEntries(formData);
    const password = data.password as string;
    const emailRetrievalConsent = data.emailRetrievalConsent === 'on';
    setPassword(password);
    setEmailRetrievalConsent(emailRetrievalConsent);
    changeLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_UP);
  };
  useEffect(() => {
    if (captchaToken) {
      (async () => {
        const res = await fetchInternalRoute<ResponseError | RegisterResponseDto>(INTERNAL_ROUTES.REGISTER, {
          method: 'POST',
          body: JSON.stringify({
            email,
            password,
            emailRetrievalConsent,
            captchaToken,
            registrationPlaceUrl: domainWithProtocol,
            ...(captchaMode ? {
              captchaMode
            } : {})
          })
        });
        changeLoading(false);
        if (res && 'errorCode' in res) {
          if (res.errorCode === UserApiErrorV1.InvalidRequestData) {
            changeErrors(['Password format is invalid. Please try again.']);
          } else {
            changeErrors(res.message as string[]);
          }
          changeErrorCode(res.errorCode);
          return;
        }
        changeCurrentStep(EAuthSteps.EMAIL_SENT_FORM);
        clearCaptchaData();
      })();
    }
  }, [captchaToken, changeLoading, clearCaptchaData, changeCurrentStep, email, emailRetrievalConsent, password, domainWithProtocol, captchaMode, changeErrors, changeErrorCode]);
  return <>
			<Title className={classNames(modalStyles.Title, styles.SignUpPasswordTitle)} data-sentry-element="Title" data-sentry-source-file="index.tsx">Choose your password</Title>
			<Description className={classNames(modalStyles.Description, styles.SignUpPasswordDescription)} data-sentry-element="Description" data-sentry-source-file="index.tsx">
				<span>You are signing up with</span>
				<span className={modalStyles.strong}>{email}</span>
			</Description>
			<Root className={classNames(formStyles.Root, styles.SignUpPasswordForm)} onSubmit={async event => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      await signUpPasswordFormAction(data);
    }} data-sentry-element="Root" data-sentry-source-file="index.tsx">
				<PasswordField name='password' withRequirements={true} invalid={Boolean(errors.length)} data-sentry-element="PasswordField" data-sentry-source-file="index.tsx" />
				<Field className={classNames(formStyles.Field, styles.CheckboxField)} name='emailRetrievalConsent' data-sentry-element="Field" data-sentry-source-file="index.tsx">
					<CheckboxRoot className={styles.CheckboxRoot} id='emailRetrievalConsent' data-sentry-element="CheckboxRoot" data-sentry-source-file="index.tsx">
						<CheckboxIndicator className={styles.CheckboxIndicator} data-sentry-element="CheckboxIndicator" data-sentry-source-file="index.tsx">
							<StaticImage src='/assets/check-icon.svg' alt='check-icon' width={16} height={16} data-sentry-element="StaticImage" data-sentry-source-file="index.tsx" />
						</CheckboxIndicator>
					</CheckboxRoot>
					<Label htmlFor='emailRetrievalConsent' className={styles.CheckboxLabel} data-sentry-element="Label" data-sentry-source-file="index.tsx">
						Email me updates on new games, special events, and exclusive offers​
					</Label>
				</Field>
				<AuthErrorList errors={errors} data-sentry-element="AuthErrorList" data-sentry-source-file="index.tsx" />
				<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
					<ContinueButton loading={loading} text='Continue' data-sentry-element="ContinueButton" data-sentry-source-file="index.tsx" />
				</Submit>
			</Root>
		</>;
};