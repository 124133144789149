import { Loader } from '@/components/Loader';
import styles from './styles.module.css';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { TCalltoaction } from '@/components/ButtonCallToAction/model';
import classNames from 'classnames';
import { ESpinnerSize } from '@/components/Spinner/model';
export const ContinueButton = ({
  loading,
  text,
  customClassname
}: {
  customClassname?: string;
  loading: boolean;
  text: string;
  styling?: TCalltoaction['styling'];
}) => {
  return <div className={classNames(styles.ContinueButton, customClassname)} data-sentry-component="ContinueButton" data-sentry-source-file="ContinueButton.tsx">
			{loading ? <Loader size={ESpinnerSize.SMALL} /> : <ButtonCallToAction text={text} />}
		</div>;
};