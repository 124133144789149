'use client';

import { useEffect, useState } from 'react';
import { CaptchaIframe, useCaptcha } from '@/features/recaptcha';
import { UserApiErrorV1 } from '@/libs/api/eagle-api-wrapper/api-gateway';
import { RECAPTCHA_ACTIONS_TYPE, RECAPTCHA_MODES } from '../recaptcha/hooks/useCaptcha';
import { SignInUpEmailForm } from './SignInUpEmailForm';
import { SignInPasswordForm } from './SignInPasswordForm';
import { AuthType } from '@/libs/api/auth';
import { EAuthSteps } from './EntryModal';
import { PrivacyPolicyLink } from '@/components/Auth/PrivacyPolicyLink/PrivacyPolicyLink';
import { SignUpPasswordForm } from './SignUpPasswordForm';
import { EmailSentForm } from './EmailSentForm';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { ResetPasswordForm } from './ResetPasswordForm';
import { ResetPasswordSuccessForm } from './ResetPasswordSuccessForm';
import { ThankYouForm } from './ThankYouForm';
import formStyles from './form.styles.module.css';
import classNames from 'classnames';
import { ResponseError } from '@/root/types/ResponseError';
export type TCommonAuthModalProps = {
  loading: boolean;
  changeLoading: (loading: boolean) => void;
  errors: string[];
  changeErrors: (errors: string[]) => void;
  changeErrorCode: (code: UserApiErrorV1 | null) => void;
  domainWithProtocol: string;
};
export type TCaptchaProps = {
  captchaToken: string | undefined;
  captchaMode: RECAPTCHA_MODES | undefined;
  getCaptchaToken: (action: RECAPTCHA_ACTIONS_TYPE) => void;
  clearCaptchaData: () => void;
};
type TEntryFormProps = {
  captchaData: ReturnType<typeof useCaptcha>;
  onLoginSuccess: () => void;
  domain: string;
  currentStep: EAuthSteps;
  changeCurrentStep: (step: EAuthSteps) => void;
  emailConfirmState: 'success' | 'error' | null;
  resetPasswordRecoveryCode: string;
  changeEmailConfirmState: (state: 'success' | 'error' | null) => void;
  byGoogle: boolean | null;
  byFacebook: boolean | null;
  byEmail: boolean | null;
};
export enum AuthProvider {
  facebook = 'Facebook',
  google = 'Google',
  arkadium = 'Arkadium',
}
export interface OAuthUserResponse {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  access_token: string;
}
export type TAuthorizedData = {
  user: OAuthUserResponse;
  eventType: AuthProvider;
};
export const EntryForm = ({
  captchaData,
  onLoginSuccess,
  domain,
  currentStep,
  changeCurrentStep,
  resetPasswordRecoveryCode,
  emailConfirmState,
  changeEmailConfirmState,
  byGoogle,
  byFacebook,
  byEmail
}: TEntryFormProps) => {
  const [errors, setErrors] = useState([] as string[]);
  const [errorCode, setErrorCode] = useState<UserApiErrorV1 | null>(null);
  const [email, setEmail] = useState<string>('');
  const [captchaMode, setCaptchaMode] = useState<RECAPTCHA_MODES | undefined>(undefined);
  const [authType, setAuthType] = useState<AuthType>(AuthType.EmailPassword);
  const [loading, setLoading] = useState(false);
  const domainWithProtocol = domain.startsWith('http') ? domain : `https://${domain}`;
  const {
    captchaToken,
    captchaTokenError,
    clearCaptchaData,
    getCaptchaToken,
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    showChallengeRecaptcha,
    setShowChallengeRecaptcha,
    clearCaptchaToken,
    isCaptchaPending
  } = captchaData;
  useEffect(() => {
    if (captchaTokenError && !errors.includes(captchaTokenError)) {
      setErrors(errors => [...errors, captchaTokenError]);
      setLoading(false);
    }
  }, [captchaTokenError, errors]);
  useEffect(() => {
    const isChallengeMode = errorCode === UserApiErrorV1.CaptchaScoreIsTooLow || errorCode === UserApiErrorV1.CaptchaValidationFailed;
    if (!isChallengeMode) {
      clearCaptchaData();
    }
    setShowChallengeRecaptcha(isChallengeMode);
    if (isChallengeMode) {
      setCaptchaMode(RECAPTCHA_MODES.CHALLENGE);
      clearCaptchaToken();
    }
  }, [errorCode, clearCaptchaData, clearCaptchaToken, setShowChallengeRecaptcha]);
  useEffect(() => {
    setErrors([]);
    setErrorCode(null);
  }, [currentStep]);
  useEffect(() => {
    if (isCaptchaPending) {
      setLoading(isCaptchaPending);
    }
  }, [isCaptchaPending]);
  const renderForm = (): React.JSX.Element | null => {
    const captchaProps: TCaptchaProps = {
      captchaToken: captchaToken,
      captchaMode: captchaMode,
      getCaptchaToken: getCaptchaToken,
      clearCaptchaData: clearCaptchaData
    };
    const commonAuthProps = {
      loading: loading,
      changeLoading: setLoading,
      errors: errors,
      changeErrors: setErrors,
      changeErrorCode: setErrorCode,
      domainWithProtocol: domainWithProtocol
    };
    switch (currentStep) {
      case EAuthSteps.SIGN_IN_UP_EMAIL:
      case EAuthSteps.GOOGLE_AUTH:
      case EAuthSteps.FACEBOOK_AUTH:
      case EAuthSteps.SIGN_IN_UP_RESEND_EMAIL:
        return <SignInUpEmailForm email={email} changeEmail={setEmail} emailConfirmState={emailConfirmState} changeEmailConfirmState={changeEmailConfirmState} authType={authType} currentStep={currentStep} changeCurrentStep={changeCurrentStep} onLoginSuccess={onLoginSuccess} changeAuthType={setAuthType} byGoogle={byGoogle} byFacebook={byFacebook} byEmail={byEmail} {...commonAuthProps} {...captchaProps} />;
      case EAuthSteps.SIGN_UP_PASSWORD:
        return <SignUpPasswordForm email={email} changeCurrentStep={changeCurrentStep} {...commonAuthProps} {...captchaProps} />;
      case EAuthSteps.SIGN_IN_PASSWORD:
      case EAuthSteps.RESEND_CONFIRMATION_EMAIL:
        return <>
						<SignInPasswordForm email={email} currentStep={currentStep} changeCurrentStep={changeCurrentStep} onLoginSuccess={onLoginSuccess} {...commonAuthProps} {...captchaProps} />
					</>;
      case EAuthSteps.FORGOT_PASSWORD:
        return <ForgotPasswordForm email={email} changeEmail={setEmail} changeCurrentStep={changeCurrentStep} {...commonAuthProps} {...captchaProps} />;
      case EAuthSteps.EMAIL_SENT_FORM:
        return <EmailSentForm email={email} changeCurrentStep={changeCurrentStep} authType={authType} {...commonAuthProps} {...captchaProps} />;
      case EAuthSteps.THANK_YOU_FORM:
        return <ThankYouForm email={email} changeCurrentStep={changeCurrentStep} authType={authType} {...commonAuthProps} {...captchaProps} />;
      case EAuthSteps.RESET_PASSWORD_FORM:
        return <ResetPasswordForm changeCurrentStep={changeCurrentStep} resetPasswordRecoveryCode={resetPasswordRecoveryCode} {...commonAuthProps} {...captchaProps} />;
      case EAuthSteps.RESET_PASSWORD_FORM_SUCCESS:
        return <ResetPasswordSuccessForm loading={loading} changeErrors={setErrors} changeErrorCode={setErrorCode} changeCurrentStep={changeCurrentStep} />;
      default:
        return null;
    }
  };
  return <>
			<div className={classNames(formStyles.KeepAliveContainer, {
      [formStyles.Active]: !showChallengeRecaptcha
    })}>
				{renderForm()}
			</div>
			<div className={classNames(formStyles.KeepAliveContainer, {
      [formStyles.Active]: showChallengeRecaptcha
    })}>
				<CaptchaIframe isVisible={showChallengeRecaptcha} iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded} data-sentry-element="CaptchaIframe" data-sentry-source-file="EntryForm.tsx" />
			</div>
			<PrivacyPolicyLink data-sentry-element="PrivacyPolicyLink" data-sentry-source-file="EntryForm.tsx" />
		</>;
};