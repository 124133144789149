'use client';

import React, { useState, useRef, useMemo, useEffect, useCallback, useContext } from 'react';
import Image from 'next/image';
import { useRouter, usePathname } from 'next/navigation';
import { useSearch } from './search';
import { useDeviceDetector } from '@/libs/utils/userAgentContext';
import CloseIcon from './CloseIcon';
import SearchIcon from './SearchIcon';
import { LocalizedLink } from '@/components/Link';
import { getLocaleFromPathname } from '@/libs/utils/url';
import styles from './styles.module.css';
import type { Anchor, Root, Content } from '@radix-ui/react-popover';
import { useScreenSize } from '@/root/libs/utils/useScreensize';
import { EScreenSize } from '@/root/libs/utils/screensize';
import { AnalyticsContext } from '@/features/arena-data/view';
type SearchProps = {
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  isFocused?: boolean;
  className?: string;
};
type SearchRenderProps = {
  isOpen: boolean;
  loadPopover: boolean;
  children: React.ReactNode;
  handleOutsideClick: any;
  renderContent: any;
};
type Match = {
  key: string;
  value: string;
};
type PointerDownOutsideEvent = CustomEvent<{
  originalEvent: PointerEvent;
}>;
type FocusOutsideEvent = CustomEvent<{
  originalEvent: FocusEvent;
}>;
const SearchRender = ({
  isOpen,
  loadPopover,
  handleOutsideClick,
  renderContent,
  children
}: SearchRenderProps) => {
  const [popoverComponents, setPopoverComponents] = useState<{
    root: typeof Root;
    anchor: typeof Anchor;
    content: typeof Content;
  } | null>(null);
  const renderResult = useMemo(() => {
    if (!loadPopover || !popoverComponents) return children;
    const {
      root: PopoverRoot,
      anchor: PopoverAnchor,
      content: PopoverContent
    } = popoverComponents;
    return <PopoverRoot open={isOpen}>
				<PopoverAnchor className={styles.searchAnchor}>{children}</PopoverAnchor>
				<PopoverContent onOpenAutoFocus={e => {
        e.preventDefault();
      }} onInteractOutside={handleOutsideClick} className={`${styles.popoverContent} padding-40-right padding-40-left `}>
					{renderContent()}
				</PopoverContent>
			</PopoverRoot>;
  }, [loadPopover, popoverComponents, isOpen, renderContent, handleOutsideClick]);
  useEffect(() => {
    if (!loadPopover || popoverComponents) return;
    import('@radix-ui/react-popover').then(({
      Root,
      Anchor,
      Content
    }) => {
      setPopoverComponents({
        root: Root,
        anchor: Anchor,
        content: Content
      });
    });
  }, [loadPopover]);
  return renderResult as React.ReactElement;
};
export const Search = ({
  isFocused,
  onFocus,
  onBlur,
  className
}: SearchProps) => {
  const router = useRouter();
  const [loadPopover, setLoadPopover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    isMobile,
    isNotPc
  } = useDeviceDetector();
  const isMobileDetected = isMobile();
  const {
    AITracks
  } = useContext(AnalyticsContext);
  const pathname = usePathname();
  const screenSize = useScreenSize();
  const isDesktop = screenSize !== EScreenSize.xs;
  const {
    query,
    setQuery,
    results,
    isSearching,
    isMalicious
  } = useSearch();
  const isOpen = query.length >= 3;
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const locale = getLocaleFromPathname(pathname);
    if (query.length >= 1) {
      router.push(`/${locale}/search/${encodeURIComponent(query.trim())}`);
    }
  };
  const handleOutsideClick = (e: React.MouseEvent<HTMLAnchorElement> | PointerDownOutsideEvent | FocusOutsideEvent) => {
    const target = e.target as Node;
    if (containerRef.current && !containerRef.current.contains(target)) {
      setQuery('');
      onBlur && onBlur(e as any);
    }
  };
  const clearInput = useCallback((e: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => {
    setQuery('');
    onBlur && onBlur(e as any);
  }, [onBlur, setQuery]);
  const handleEscKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      inputRef.current && inputRef.current.blur();
      clearInput(event);
    }
  }, [clearInput]);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => handleEscKeyDown(event);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleEscKeyDown]);
  const trimQuery = (query: string, maxLength: number) => {
    if (query.length > maxLength) {
      return `${query.substring(0, maxLength)}...`;
    }
    return query;
  };
  const {
    games,
    categories
  } = useMemo(() => {
    if (query.length < 3) {
      return {
        games: [],
        categories: []
      };
    }
    const categoryMap = new Map();
    const formattedGames = results ? results.map(result => {
      return {
        name: result.item.meta.name || result.item.name,
        image: result.item.meta.thumbs.graphic_56x56,
        page: 'games',
        slug: result.item.slug,
        alias: result.item.meta.alias
      };
    }) : [];
    results?.forEach(result => {
      result.matches.filter((match: Match) => match.key === 'meta.categories.name').forEach((match: Match) => {
        const categoryObj = result.item.meta.categories.find((cat: {
          name: string;
        }) => cat.name === match.value);
        const trimmedName = match.value.trim(),
          existingCategory = categoryMap.get(trimmedName);
        if (categoryObj && ['', null, undefined].includes(existingCategory?.image)) {
          categoryMap.set(trimmedName, {
            name: trimmedName,
            image: categoryObj.image,
            page: categoryObj.slug,
            slug: encodeURIComponent(match.value.toLowerCase().trim().replace(/\s+/g, ''))
          });
        }
      });
    });
    return {
      games: formattedGames,
      categories: Array.from(categoryMap.values())
    };
  }, [results, query]);
  const noResults = results?.length === 0;
  const toggleScrollableBody = (scrollable: boolean) => {
    document.body.style.position = scrollable ? 'initial' : 'fixed';
    document.body.style.overflow = scrollable ? 'auto' : 'hidden';
    document.body.style.touchAction = scrollable ? 'auto' : 'none';
  };
  const renderContent = () => {
    const popoverContent = containerRef.current?.querySelector('[data-radix-popper-content-wrapper]');
    if (typeof document !== 'undefined' && isNotPc() && isFocused && popoverContent) {
      toggleScrollableBody(false);
    }
    if (isMalicious) {
      return <div className={`${styles.noResults}`}>
					Oops! There was an issue with the search terms provided. Please review your search terms and try
					again.
				</div>;
    }
    if (isSearching) {
      return <div className={`${styles.noResults}`}>Loading...</div>;
    }
    if (noResults) {
      return <div className={`${styles.noResults}`}>Sorry, no results found...</div>;
    }
    return <>
				<div className={styles.resultsContainer}>
					<ResultList items={categories} title='Categories' onClick={handleOutsideClick} data-sentry-element="ResultList" data-sentry-source-file="index.tsx" />
					<ResultList items={games} title='Games' onClick={handleOutsideClick} data-sentry-element="ResultList" data-sentry-source-file="index.tsx" />
				</div>
				<div className={`${styles.seeAllResults} padding-16-bottom padding-16-top margin-40-right margin-40-left`}>
					<div className={`${styles.seeAllGradientOverlay} ${isDesktop ? styles.gradientWhite : styles.gradientGray}`}></div>
					<LocalizedLink className={`text-16 fw-regular ${styles.seeAllResultsAnchor}`} href={`/search/${query}`} data-sentry-element="LocalizedLink" data-sentry-source-file="index.tsx">
						<SearchIcon data-sentry-element="SearchIcon" data-sentry-source-file="index.tsx" />
						<span className='margin-4-left'>See all</span>
						<strong className='fw-semibold'>&nbsp;&quot;{trimQuery(query, 20)}&quot;&nbsp;</strong>
						results
					</LocalizedLink>
				</div>
			</>;
  };
  const showCloseIcon = query || isMobileDetected;
  useEffect(() => {
    if (!isFocused && isNotPc()) {
      toggleScrollableBody(true);
    }
    isFocused && AITracks.searchNav();
    if (isDesktop && isFocused && typeof document !== 'undefined') {
      const sidebarToggle: any = document?.body?.querySelector('#sidebarToggle');
      if (sidebarToggle) {
        sidebarToggle.checked = false;
      }
    }
    const mainElement = document.querySelector('main');
    if (mainElement) {
      mainElement.style.pointerEvents = isFocused ? 'none' : '';
    }
    return () => {
      if (mainElement) {
        mainElement.style.pointerEvents = '';
      }
    };
  }, [isFocused]);
  const handleInputTouchStart = () => {
    // Fix for Firefox on Android: Prevent scrolling for mobile while focusing - 400 is animation delay
    toggleScrollableBody(false);
    setTimeout(() => {
      toggleScrollableBody(true);
    }, 400);
  };
  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setLoadPopover(true);
    onFocus && onFocus(e);
  };
  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!isOpen) {
      setQuery('');
      onBlur && onBlur(e);
    }
  };
  const props: React.InputHTMLAttributes<HTMLInputElement> = {};
  if (isFocused) {
    props.autoFocus = true;
  } else {
    props.placeholder = 'Search';
  }
  return <>
			{!isDesktop && isOpen && <div className={`${styles.backButton}`}>
					<Image src={'/assets/arrow-narrow-left.svg'} width={24} height={24} alt={'back'} />
				</div>}
			<div ref={containerRef} className={`${styles.searchContainer}`}>
				<SearchRender handleOutsideClick={handleOutsideClick} isOpen={isOpen} renderContent={renderContent} loadPopover={loadPopover} data-sentry-element="SearchRender" data-sentry-source-file="index.tsx">
					<form onSubmit={handleSubmit} className='relative'>
						<div className={styles.searchIcon}>
							<SearchIcon data-sentry-element="SearchIcon" data-sentry-source-file="index.tsx" />
						</div>
						<input ref={inputRef} type='text' value={query} onChange={e => setQuery(e.target.value)} onTouchStart={handleInputTouchStart} onFocus={handleInputFocus} onBlur={handleInputBlur} className={`${className ?? ''} ${styles.searchInput} ${isFocused ? styles.searchInputFocused : ''} ${isFocused ? 'ark-ui-search-active' : 'ark-ui-search'}`} aria-label='Search' {...props} />
						{showCloseIcon && <button type='button' onClick={clearInput} className={`${styles.clearButton} ${isFocused ? styles.clearButton__visible : ''}`} aria-label='Clear'>
								<CloseIcon />
							</button>}
					</form>
				</SearchRender>
			</div>
		</>;
};
type ResultItem = {
  name: string;
  image: string;
  page: string;
  slug: string;
  alias: string;
};
type ResultListProps = {
  items: ResultItem[];
  title: string;
  onClick: (event: any) => void;
};
const ResultList: React.FC<ResultListProps> = ({
  items,
  title,
  onClick
}) => {
  return <div className={styles.columnContainer} data-sentry-component="ResultList" data-sentry-source-file="index.tsx">
			<h3 className={`text-20 fw-semibold ${styles.resultTitle}`}>{title}</h3>
			<div className={`${styles.resultList} ${title?.toLowerCase() === 'categories' ? ' margin-56-right' : null} `}>
				{items.length === 0 ? <div className='text-16 fw-regular'>Sorry, no categories found...</div> : items.map((item, index) => <LocalizedLink className={styles.resultItemLink} key={index} href={`/${item.page}/${item.alias ? item.alias : item.slug}`} onClick={onClick} passHref>
							<div className={styles.resultItem}>
								<div className={styles.resultImageContainer}>
									{!item.image ? <div style={{
              width: '56px',
              height: '56px',
              backgroundColor: '#ddd'
            }} className={styles.resultImage}></div> : <Image src={item.image} className={styles.resultImage} width={56} height={56} alt={item.name} />}
								</div>
								<div className={`text-16 fw-regular ${styles.capitalize}`}>{item.name}</div>
							</div>
						</LocalizedLink>)}
			</div>
			<div className={`${styles.gradientWhite}`}></div>
		</div>;
};
export default ResultList;