import React, { LegacyRef } from 'react';
import classNames from 'classnames';
import styles from './CaptchaIframe.module.css';
type TCaptchaIframeProps = {
  iframeRef: LegacyRef<HTMLIFrameElement> | null;
  iframeURL: string;
  setIsIframeCaptchaLoaded: (value: boolean) => void;
  isVisible: boolean;
};
const CaptchaIframe = React.memo(({
  isVisible,
  iframeRef,
  iframeURL,
  setIsIframeCaptchaLoaded
}: TCaptchaIframeProps) => {
  return <div className={classNames({
    [styles.contentWrapper]: isVisible,
    [styles.invisibleCaptcha]: !isVisible
  })}>
				<div className='modal-content'>
					<h2>
						<strong>Verify you&apos;re human</strong>
					</h2>
					<iframe ref={iframeRef} onLoad={() => setIsIframeCaptchaLoaded(true)} src={iframeURL} title='Recaptcha for Eagle' className={styles.recaptchaIframe} />
				</div>
			</div>;
});
CaptchaIframe.displayName = 'CaptchaIframe';
export { CaptchaIframe };