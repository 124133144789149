const applicationJSONRequests = ['POST', 'PATCH'];
export const fetchJson = (url: string, options?: RequestInit) => {
	options = options || {};
	options.headers = (options.headers as Record<string, string>) || { 'Content-Type': 'application/json' };
	if (
		!options.headers['Content-Type'] &&
		applicationJSONRequests.indexOf((options.method as string).toUpperCase()) !== -1
	) {
		options.headers['Content-Type'] = 'application/json';
	}

	return fetch(url, options).then((res: Response) => {
		return res.text().then((text) => {
			let json;

			try {
				json = JSON.parse(text);
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
			} catch (e) {
				// Swallow
			}

			if (res.status >= 200 && res.status < 300) {
				return json || text;
			} else {
				const err = new HttpError(res);
				console.error(JSON.stringify(err, null, 2));
				if (json) {
					json = Array.isArray(json) ? json[0] : json;
				}
				err.body = json || text;

				console.error(JSON.stringify(err, null, 2));
				throw err;
			}
		});
	});
};

export type ErrorResponseObj = {
	message: string;
	details: string;
	errorCode: number;
};

export class HttpError extends Error {
	response: Response;
	body?: ErrorResponseObj;

	constructor(response: Response) {
		super(`${response.status} - ${response.statusText} for ${response.url}`);
		this.name = 'HttpError';
		this.response = response;
	}
}
