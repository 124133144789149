'use client';

import { Root, Submit } from '@radix-ui/react-form';
import formStyles from '../form.styles.module.css';
import styles from './styles.module.css';
import modalStyles from '@/components/Modal/styles.module.css';
import classNames from 'classnames';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
import { ContactSupportBlock } from '@/components/Auth/ContactSupportBlock/ContactSupportBlock';
import { Description, Title } from '@radix-ui/react-dialog';
import { ContinueButton } from '@/components/Auth/ContinueButton/ContinueButton';
import { EAuthSteps } from '../EntryModal';
import { RECAPTCHA_ACTIONS } from '@/features/recaptcha';
import { resendEmail } from '@/root/libs/api/register';
import { AuthType } from '@/root/libs/api/auth';
import { useEffect, useState } from 'react';
import { TCommonAuthModalProps, TCaptchaProps } from '../EntryForm';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { ResponseError } from '@/root/types/ResponseError';
export type TEmailSentFormProps = {
  email: string;
  changeCurrentStep: (step: EAuthSteps) => void;
  authType: AuthType;
  isThankYouForm?: boolean;
} & TCommonAuthModalProps & TCaptchaProps;
export const EmailSentForm = ({
  email,
  errors,
  loading,
  changeErrors,
  changeErrorCode,
  changeCurrentStep,
  changeLoading,
  captchaToken,
  captchaMode,
  getCaptchaToken,
  clearCaptchaData,
  domainWithProtocol,
  authType,
  isThankYouForm
}: TEmailSentFormProps) => {
  const [isEmailSentNotification, setIsEmailSentNotification] = useState(false);
  const resendEmailFormAction = () => {
    changeErrors([]);
    setIsEmailSentNotification(false);
    changeLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
  };
  useEffect(() => {
    if (captchaToken) {
      changeLoading(true);
      (async () => {
        const res = await fetchInternalRoute<void | ResponseError>(INTERNAL_ROUTES.RESEND_EMAIL, {
          method: 'POST',
          body: JSON.stringify({
            email,
            authType: AuthType.EmailPassword,
            resendConfirmationFormUrl: domainWithProtocol,
            captchaToken,
            ...(captchaMode ? {
              captchaMode
            } : {})
          })
        });
        // const res = await resendEmail({
        // 	email,
        // 	authType,
        // 	resendConfirmationFormUrl: domainWithProtocol,
        // 	captchaToken,
        // 	...(captchaMode ? { captchaMode } : {}),
        // });

        changeLoading(false);
        if (res && 'errorCode' in res) {
          changeErrors(res.message as string[]);
          changeErrorCode(res.errorCode);
          return;
        }
        setIsEmailSentNotification(true);
        clearCaptchaData();
      })();
    }
  }, [captchaToken, changeLoading, clearCaptchaData, changeCurrentStep, email, domainWithProtocol, captchaMode, changeErrors, changeErrorCode, authType]);
  const getTitleAndDescription = () => {
    if (isThankYouForm) {
      return null;
    }
    return <>
				<Title className={classNames(modalStyles.Title, styles.EmailSentTitle)} data-sentry-element="Title" data-sentry-source-file="index.tsx">You&apos;re almost done​</Title>
				<Description className={classNames(styles.EmailSentDescription)} data-sentry-element="Description" data-sentry-source-file="index.tsx">
					We just sent an email to <span className={modalStyles.strong}>{email}</span>
				</Description>
				<Description className={classNames(styles.EmailSentDescription)} data-sentry-element="Description" data-sentry-source-file="index.tsx">
					Follow the instructions to finish signing up.
				</Description>
				<Description className={classNames(styles.EmailSentDescription)} data-sentry-element="Description" data-sentry-source-file="index.tsx">
					Can’t find the email? Check your spam folder or resend it​
				</Description>
			</>;
  };
  return <>
			{getTitleAndDescription()}
			<Root className={classNames(formStyles.Root, styles.EmailSentForm)} action={resendEmailFormAction} data-sentry-element="Root" data-sentry-source-file="index.tsx">
				<AuthErrorList errors={errors} data-sentry-element="AuthErrorList" data-sentry-source-file="index.tsx" />
				<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
					<div className={styles.EmailSentResendButton}>
						<ContinueButton loading={loading} text='Resend email' customClassname={isThankYouForm ? styles.ThankYouButton : ''} data-sentry-element="ContinueButton" data-sentry-source-file="index.tsx" />
						<div className={classNames(styles.EmailSentNotification, {
            [styles.visible]: isEmailSentNotification
          })}>
							Email sent
						</div>
					</div>
				</Submit>
			</Root>
			<ContactSupportBlock data-sentry-element="ContactSupportBlock" data-sentry-source-file="index.tsx" />
		</>;
};