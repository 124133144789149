'use client';

import { ProfileText } from '@/components/Profile/Components/ProfileText/ProfileText';
import React, { useContext, useState } from 'react';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import classNames from 'classnames';
import style from '@/components/Profile/Components/ProfileFields/style.module.css';
import { AnalyticsContext } from '@/features/arena-data/view';
import { logger } from '@/libs/utils/logger';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { ResponseError } from '@/root/types/ResponseError';
export type ProfileEmailConsentProps = {
  userDataStore: UserProfileDTO;
};
export const EmailConsent = ({
  userDataStore
}: ProfileEmailConsentProps) => {
  const {
    AITracks
  } = useContext(AnalyticsContext);
  const [value, setValue] = useState<boolean>(userDataStore.emailRetrievalConsent);
  const [disabled, setDisabled] = useState<boolean>(false);
  const doUpdate = (newVal: boolean) => {
    const value = newVal;
    setDisabled(true);
    fetchInternalRoute<UserProfileDTO | ResponseError>(INTERNAL_ROUTES.CHANGE_EMAIL_CONSENT, {
      method: 'PATCH',
      body: JSON.stringify({
        emailRetrievalConsent: value
      })
    }).then(async (updatedProfile: any) => {
      setValue(updatedProfile.emailRetrievalConsent);
      await AITracks.profileEmailConsentOptIn();
    }).catch(error => {
      logger.log(`Profile - userRetrievalConsent action, Error: `, error);
    }).finally(() => {
      setDisabled(false);
    });
  };
  return <>
			<ProfileText text={`I would like to receive Arkadium Newsletters`} color={'black'} isBold={true} data-sentry-element="ProfileText" data-sentry-source-file="EmailConsent.tsx" />
			<div className={classNames(style.InputWrapper)}>
				<div className={classNames(style.InputToggler)}>
					<input type='checkbox' disabled={disabled} checked={Boolean(value)} onChange={(ev: any) => {
          ev.preventDefault();
        }} onClick={() => {
          doUpdate(!value);
        }} data-testid={`profile-email-consent`} />
				</div>
				<ProfileText text={'Announcements and special offers'} color={'gray'} addClasses={[style.__EmailConsentExtra]} data-sentry-element="ProfileText" data-sentry-source-file="EmailConsent.tsx" />
			</div>
		</>;
};