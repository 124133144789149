'use client';

import React, { SyntheticEvent, useContext } from 'react';
import style from './style.module.css';
import Image from 'next/image'; // @ts-ignore
import { Splide, SplideSlide } from '@splidejs/react-splide';
import classNames from 'classnames';
import { EAvatarsListItemType, TAvatarsListItem, TAvatarsLists } from '@/components/Profile';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import { ProfileError } from '@/components/Profile/Components/ProfileError/ProfileError';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { TAllAvatarsListItem } from '@/components/Profile/Components/ProfileAvatar/ProfileAvatar';
import { updateUserAvatar } from '@/root/actions/profile/updateUserAvatar.action';
import { useFormState } from 'react-dom';
import { AnalyticsContext } from '@/features/arena-data/view';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
export type ProfileAvatarSelectionProps = {
  avatars: TAvatarsLists;
  setIsSelectAvatar: (value: boolean) => void;
  action?: (val: string) => Promise<string>;
  userDataStore?: UserProfileDTO;
  mainStateUpdate?: (val: string) => void;
};
export type TProfileAvatarFormState = {
  avatarSelected: string;
  error: string;
};
export const ProfileAvatarSelection = ({
  avatars,
  setIsSelectAvatar,
  userDataStore,
  mainStateUpdate
}: ProfileAvatarSelectionProps) => {
  const {
    AITracks
  } = useContext(AnalyticsContext);
  const formHandler = async (prevState: TProfileAvatarFormState, formData: FormData): Promise<TProfileAvatarFormState> => {
    const updatedState: TProfileAvatarFormState = {
      ...(await fetchInternalRoute<TProfileAvatarFormState>(INTERNAL_ROUTES.UPDATE_AVATAR, {
        method: 'PATCH',
        body: JSON.stringify({
          prevState,
          formData
        })
      }))
      // ...(await updateUserAvatar(prevState, formData)),
    } as TProfileAvatarFormState;
    if (!updatedState.error) {
      mainStateUpdate && mainStateUpdate?.(updatedState.avatarSelected);
      AITracks?.profileAvatarSelected?.();
      setIsSelectAvatar(false);
    }
    return updatedState;
  };
  const [state, formAction, pending] = useFormState<TProfileAvatarFormState>(formHandler as any, {
    avatarSelected: userDataStore?.avatar ?? '',
    error: ''
  });
  const {
    avatarSelected,
    error
  } = state;
  // COMPONENTS
  const groupByNumber = 3;
  const avatarsGrouped: TAllAvatarsListItem[][] = getAvatarsGrouped(avatars, groupByNumber);
  const isChosenAvatar = (avatar: TAllAvatarsListItem) => avatar.imgUrl === avatarSelected;
  return <div className={classNames(style.AvatarSelection, {
    [style.AvatarSelection__pending]: pending
  })} data-testid={`profile-avatar-selection`} data-sentry-component="ProfileAvatarSelection" data-sentry-source-file="ProfileAvatarSelection.tsx">
			<header className={style.AvatarSelectionHeader} data-testid={`profile-avatar-selection-header`}>
				<h2 className={style.AvatarSelectionHeaderTitle}>Select avatar</h2>
				<p className={style.AvatarSelectionHeaderExtraText}>{/*EXTRA TEXT PLACEHOLDER*/}</p>
			</header>
			<form action={formAction} className={style.AvatarSelectionContent} data-testid={`profile-avatar-selection-content`}>
				<Splide options={{
        type: 'slide',
        pauseOnHover: true,
        pauseOnFocus: true,
        focus: 0,
        pagination: true,
        // Enable pagination
        omitEnd: true,
        autoWidth: true,
        lazyLoad: true,
        slideFocus: true,
        focusableNodes: ''
      }} data-sentry-element="Splide" data-sentry-source-file="ProfileAvatarSelection.tsx">
					{avatarsGrouped.map((group, index) => <SplideSlide key={index}>
							<div className={style.AvatarSelectionSlideGroup} key={`${index}-box`}>
								{group.map(avatar => <label key={`${index}-box_${avatar.id}`} className={style.AvatarSelectionSlideAvatarWrapper} data-testid={`profile-avatar-selection-slide-${avatar.id}`}>
										<input type='radio' name='avatarSelected' value={avatar?.imgUrl ?? ''} className={style.AvatarSelectionSlideAvatarInput} defaultChecked={isChosenAvatar(avatar)} />
										<Image className={classNames(style.AvatarSelectionSlideAvatar, {
                [style.AvatarSelectionSlideAvatarPremium]: avatar.isPremium
              })} src={avatar.imgUrl ?? ''} alt={`Avatar image ${avatar.id}`} width={136} height={136} />
										{avatar.isPremium && <div className={style.AvatarSelectionSlideAvatarPremiumBadge} data-testid={`profile-avatar-selection-premium-badge`} />}
									</label>)}
							</div>
						</SplideSlide>)}
				</Splide>
			</form>
			<footer className={style.AvatarSelectionFooter}>
				<ButtonCallToAction text={'Skip'} size='medium' styling={'outline'} addClass={style.AvatarSelectionFooterButton} onClick={() => setIsSelectAvatar(false)} data-sentry-element="ButtonCallToAction" data-sentry-source-file="ProfileAvatarSelection.tsx" />
				<ButtonCallToAction text={'Save'} size='medium' styling={'filled'} addClass={style.AvatarSelectionFooterButton} testId={`profile-button`} onClick={(ev: SyntheticEvent) => {
        ev.preventDefault();
        ev?.currentTarget?.parentElement?.parentElement?.querySelector('form')?.requestSubmit?.();
      }} data-sentry-element="ButtonCallToAction" data-sentry-source-file="ProfileAvatarSelection.tsx" />
			</footer>
			<aside className={style.AvatarSelectionErrors}>
				<ProfileError error={error} data-sentry-element="ProfileError" data-sentry-source-file="ProfileAvatarSelection.tsx" />
			</aside>
		</div>;
};
function getAvatarsGrouped(avatars: TAvatarsLists, groupByNumber: number): TAllAvatarsListItem[][] {
  const avatarsListMapper = (avatarsList: TAvatarsListItem[]): TAllAvatarsListItem[] => avatarsList.map(avatar => ({
    id: avatar?.id ?? null,
    imgUrl: avatar?.image?.url ?? '',
    imgId: `${avatar?.image?.id ?? ''}`,
    isPremium: avatar?.type === EAvatarsListItemType.Premium
  }));
  // PREPARING MAPPED AVATARS LISTS
  const premiumAvatars: TAllAvatarsListItem[] = avatarsListMapper(avatars?.premiumAvatars ?? []);
  const regularAvatars: TAllAvatarsListItem[] = avatarsListMapper(avatars?.regularAvatars ?? []);
  const avatarsGrouped: TAllAvatarsListItem[][] = [];
  // PREPARING GROUPS BY PREMIUM AVATARS
  premiumAvatars.forEach(avatar => {
    avatarsGrouped.push([avatar]);
  });
  // REFILLING GROUPS BY REGULAR AVATARS
  let groupProcessingIndex: number = 0;
  const addToGroup = (item: any, groupsArray: any[][] = avatarsGrouped, groupBy: number = groupByNumber) => {
    const group = groupsArray[groupProcessingIndex];
    if (!group) {
      groupsArray.push([]);
    }
    if (group.length < groupBy) {
      group.push(item);
      if (group.length === groupBy) {
        groupProcessingIndex++;
      }
    } else {
      addToGroup(item, groupsArray, groupProcessingIndex + 1);
    }
  };
  regularAvatars.forEach((avatar, index) => {
    addToGroup(avatar);
  });
  return avatarsGrouped;
}