'use client';

import styles from './styles.module.css';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Image from 'next/image';
type TPassRequirements = {
  text: string;
  isValid: boolean | null;
};
export const PasswordRequirements = ({
  password
}: {
  password: string;
}) => {
  const [passwordRequirements, setPasswordRequirements] = useState<TPassRequirements[]>([{
    text: 'Minimum of 8 characters',
    isValid: null
  }, {
    text: 'One UPPERCASE letter',
    isValid: null
  }]);
  useEffect(() => {
    if (password) {
      if (password.length < 8) {
        setPasswordRequirements(prev => [{
          text: prev[0].text,
          isValid: false
        }, {
          ...prev[1]
        }]);
      } else {
        setPasswordRequirements(prev => [{
          text: prev[0].text,
          isValid: true
        }, {
          ...prev[1]
        }]);
      }
      if (/[A-Z]/.test(password)) {
        setPasswordRequirements(prev => [{
          ...prev[0]
        }, {
          text: prev[1].text,
          isValid: true
        }]);
      } else {
        setPasswordRequirements(prev => [{
          ...prev[0]
        }, {
          text: prev[1].text,
          isValid: false
        }]);
      }
    }
  }, [password]);
  return <div className={styles.PasswordRequirements} data-sentry-component="PasswordRequirements" data-sentry-source-file="PasswordRequirements.tsx">
			<p>Make sure your password contains:</p>
			{passwordRequirements.map(requirement => <p key={requirement.text} className={styles.PasswordRequirement}>
					{requirement.text}
					{requirement.isValid !== null ? <Image src={requirement.isValid ? '/assets/check.svg' : '/assets/x-invalid-icon.svg'} alt={'check-icon'} width={16} height={16} className={classNames(styles.CheckIcon, {
        [styles.isValid]: requirement.isValid
      })} /> : null}
				</p>)}
		</div>;
};