'use client';

import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { $browserTrackingConsent, $gdprConsentStore, $readyToLoadMicrosoft1DS, $targetingConsentStore, fetchUserCountryFx, getBrowserTrackingConsentFx } from '@/features/analytics/model';
import { $isMicrosoftDomain, setIsSidebarHidden } from '@/app.model';
import { usePathname, useSearchParams } from 'next/navigation';
import classNames from 'classnames';
import { $gameStateStore } from '@/features/games/model';
import { TopBar } from '@/components/TopBar';
import { Footer } from '@/components/Footer';
import Script from 'next/script';
import { $videoAdUrl, arkadiumSdkLoaded, SDK_ENV } from '@/features/arkadiumsdk/model';
import { ExternalScripts } from '@/features/external-scripts';
import { $externalScriptsStore, externalScriptLoaded } from '@/features/external-scripts/model';
import { SideBar } from '@/components/SideBar';
import { TLink } from '@/libs/utils';
import { getBrowserCookie, getPageSlug } from '@/libs/utils/utils';
import { DEFAULT_LOCALE, GUARDED_ROUTES } from '@/libs/utils/constants';
import { useCMPInit } from './useCMPInit';
import { configureLoggerFx, logger } from '@/libs/utils/logger';
import { GameState } from '@/root/libs/enums/GameState';
import { $arenaDataStore } from '@/app.model';
import Pixel from '@/components/Pixel';
import { NotificationBar } from '@/components/NotificationBar';
import { AITracksDefault, useAnalytics } from '../analytics/AI/AITracks';
import { useWebVitalsFx } from '../web-vitals/useWebVitalsFx';
import { buildTheySellLibPath } from '../ad/model';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import { useCaptcha } from '@/features/recaptcha';
import { useRouter } from 'next/navigation';
import { ResponseError } from '@/root/types/ResponseError';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { TSuccessLogoutResponse } from '@/root/libs/api/login';
type TProps = Readonly<{
  children: ReactNode;
  domain: string;
  arenaMaxWidth: number | null | undefined;
}>;
const zIndexStartVal = 100;
export const AnalyticsContext = createContext<{
  AITracks: any;
  AIReadyAsDep: boolean;
}>({
  AITracks: AITracksDefault,
  AIReadyAsDep: false
});
const AnalyticsContextProvider = AnalyticsContext.Provider;
export const ArenaView = ({
  children,
  domain
}: TProps) => {
  const arenaData = useUnit($arenaDataStore);
  const externalScripts = useUnit($externalScriptsStore);
  const onScriptLoad = useUnit(externalScriptLoaded);
  const readyToLoadMicrosoft1DS = useUnit($readyToLoadMicrosoft1DS);
  const gameState = useUnit($gameStateStore);
  const browserTrackingConsent = useUnit($browserTrackingConsent);
  const sdkLoaded = useUnit(arkadiumSdkLoaded);
  const setIsSidebarHiddenFx = useUnit(setIsSidebarHidden);
  const targetingConsent = useUnit($targetingConsentStore);
  const gdprConsent = useUnit($gdprConsentStore);
  const isMicrosoft = useUnit($isMicrosoftDomain);
  const isTrackingEnabled = browserTrackingConsent !== null && isMicrosoft && targetingConsent && gdprConsent;
  const initBrowserTrackingConsent = useUnit(getBrowserTrackingConsentFx);
  const initGeoService = useUnit(fetchUserCountryFx);
  const initLogger = useUnit(configureLoggerFx);
  const buildStrapiImage = (url?: string) => url ? process.env.NODE_ENV === 'development' ? `${url}` : url : '';
  const topbarOverrides = arenaData.layout?.styleOverride?.topbar,
    topbarItemOverrides = arenaData.layout?.styleOverride?.topbarLink,
    topbarItemOverridesNormal = topbarItemOverrides?.find((i: any) => i.state === 'normal'),
    topbarItemOverridesHover = topbarItemOverrides?.find((i: any) => i.state === 'hover'),
    topbarAttributes = {
      links: arenaData.layout?.topBarLinks,
      logo: buildStrapiImage(arenaData.logo?.url),
      logoLabel: arenaData.logo_label,
      override_color_topbar_background: topbarOverrides?.background,
      override_color_item_normal_label: topbarItemOverridesNormal?.color,
      override_color_item_hover_background: topbarItemOverridesHover?.background
    };
  const videoAdsUrl = useUnit($videoAdUrl);

  //init all hoooks related to CMP
  useCMPInit();
  const featureToggles = arenaData.arena_feature?.features?.list;
  const authFeatureToggle = featureToggles?.auth;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState<UserProfileDTO | null>(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isAuthFeatureEnabled, setIsAuthFeatureEnabled] = useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const onLogout = useCallback(async () => {
    const res = await fetchInternalRoute<TSuccessLogoutResponse | ResponseError>(INTERNAL_ROUTES.LOGOUT);
    if ((res as ResponseError).errorCode) {
      logger.error(res);
      return;
    }
    if ((res as TSuccessLogoutResponse).isSuccess) {
      setUserData(null);
      setIsLoggedIn(false);
      const pageSlug = getPageSlug(pathname);
      if (GUARDED_ROUTES.includes(pageSlug)) {
        //more than one page
        if (window.history.length > 2) {
          router.back();
        } else {
          router.push('/');
        }
      }
      return;
    }
    logger.error('Logout error', res);
    setIsLoggedIn(false);
    setUserData(null);
  }, [pathname, router]);
  useEffect(() => {
    const checkIfAuthFeatureEnabled = () => {
      const appLevelFeatureEnabled = process.env.AUTH_FEATURE_ENABLED === 'true';
      const allSubfeaturesAreDisabled = !authFeatureToggle?.byGoogle && !authFeatureToggle?.byFacebook && !authFeatureToggle?.byEmail;
      return Boolean(appLevelFeatureEnabled && authFeatureToggle?.enabled && !allSubfeaturesAreDisabled);
    };
    const isAuthFeatureEnabled = checkIfAuthFeatureEnabled();
    setIsAuthFeatureEnabled(isAuthFeatureEnabled);
  }, [authFeatureToggle?.byEmail, authFeatureToggle?.byFacebook, authFeatureToggle?.byGoogle, authFeatureToggle?.enabled]);

  //check if user is logged in on load
  useEffect(() => {
    if (isAuthFeatureEnabled) {
      const checkForLogout = () => {
        const removeQueryParam = (param: string) => {
          const nextSearchParams = new URLSearchParams(searchParams.toString());
          nextSearchParams.delete(param);
          const newPathname = nextSearchParams.toString() ? `${pathname}?${nextSearchParams.toString()}` : pathname;
          router.replace(newPathname);
        };
        const logoutQP = searchParams.get('logout');
        if (logoutQP) {
          onLogout();
          removeQueryParam('logout');
        }
      };
      handleGetUserProfile();
      checkForLogout();
    }
  }, [isAuthFeatureEnabled, onLogout, pathname, router, searchParams]);
  const handleGetUserProfile = () => {
    fetchInternalRoute<UserProfileDTO | ResponseError>(INTERNAL_ROUTES.PROFILE, {
      method: 'GET'
    }).then(userData => {
      logger.debug({
        userData
      });
      if (userData) {
        if ('errorCode' in userData) {
          setUserData(null);
        } else {
          setIsLoggedIn(true);
          setUserData(userData);
        }
      }
    });
  };
  const handleLoginSuccess = () => {
    setIsLoginModalOpen(false);
    handleGetUserProfile();
  };
  const [AITracks, AIReadyAsDep] = useAnalytics();
  useWebVitalsFx(AITracks, AIReadyAsDep);
  useEffect(() => {
    AIReadyAsDep && AITracks.PageView();
  }, [AIReadyAsDep, AITracks]);
  useEffect(() => {
    const initServices = async () => {
      await initBrowserTrackingConsent();
      initGeoService();
      initLogger(process.env.BUILD_ENV === 'development');
    };
    initServices();
  }, [initBrowserTrackingConsent, initGeoService, initLogger]);
  function getLocaleFromPathname(pathname: string): string {
    const locale = pathname.split('/')[1];
    return locale;
  }

  //Transform sidebar links to include locale -> sidebar is uikit component so can't use localized link component
  const [locale, setLocale] = useState(getLocaleFromPathname(pathname) || DEFAULT_LOCALE);
  useEffect(() => {
    const cookieLocale = getBrowserCookie('locale');
    if (cookieLocale && cookieLocale !== locale) {
      setLocale(cookieLocale);
    }
  }, [locale]);
  const processUikitLinks = (links: TLink[] | undefined): TLink[] => {
    if (!links) return [];
    return links.map(link => {
      if (/^https?:\/\//.test(link.href)) return link;
      return {
        ...link,
        href: `/${locale}${link.href}`
      };
    });
  };
  const sidebarHiddenClosedState = arenaData.layout?.topBar === false && arenaData.layout?.topBarLinks?.length > 0 || arenaData.layout?.sideBar === false || pathname?.includes?.('/games/');
  setIsSidebarHiddenFx(sidebarHiddenClosedState);
  const sidebarData = arenaData.layout?.sidebar_groups?.map(g => ({
      ...g,
      icon: buildStrapiImage(g.icon?.url),
      sidebar_items: g.sidebar_items?.map((item: any) => ({
        ...item,
        icon: buildStrapiImage(item.icon?.url),
        link: {
          ...item.link,
          href: item.link?.href ? processUikitLinks([item.link])[0].href : undefined
        }
      }))
    })),
    sidebarOverrides = arenaData.layout?.styleOverride?.sidebar,
    sidebarItemOverrides = arenaData.layout?.styleOverride?.sidebarItem,
    sidebarItemOverridesNormal = sidebarItemOverrides?.find((i: any) => i.state === 'normal'),
    sidebarItemOverridesHover = sidebarItemOverrides?.find((i: any) => i.state === 'hover'),
    sidebarAttributes = {
      sidebarHiddenClosedState,
      groups: sidebarData,
      topbarLinks: processUikitLinks(arenaData.layout?.topBarLinks),
      override_color_item_normal_label: sidebarItemOverridesNormal?.color,
      override_color_item_hover_background: sidebarItemOverridesHover?.background,
      override_color_item_hover_border: sidebarItemOverridesHover?.border,
      override_color_sidebar_background: sidebarOverrides?.background,
      override_color_sidebar_divider: sidebarOverrides?.divider,
      override_color_sidebar_toggle: sidebarOverrides?.toggle,
      override_color_sidebar_toggle_background: sidebarOverrides?.toggleBackground || topbarOverrides?.background,
      zIndexStartVal
    };
  const showSidebar = (arenaData.layout?.topBar || arenaData.layout?.sideBar) && (arenaData.layout?.topBarLinks?.length > 0 || sidebarData?.find(g => g?.link?.label || g?.sidebar_items?.find((i: any) => i?.link?.label)));
  useEffect(() => {
    [GameState.GAME, GameState.REWARD, GameState.INTERSTITIAL].includes(gameState) ? document.body.classList.add('gameRunning') : document.body.classList.remove('gameRunning');
  }, [gameState]);
  const footerData = {
    logo: arenaData?.layout?.footer?.logo ?? null,
    groups: arenaData?.layout?.footer?.groups ?? null,
    socialLinks: arenaData?.layout?.footer?.socialLinks ?? null,
    backgroundColor: arenaData?.layout?.footer?.background ?? null,
    fontColor: arenaData?.layout?.footer?.fontColor ?? null,
    text: arenaData?.layout?.footer?.text ?? null,
    manageCookiesLabel: arenaData?.layout?.footer?.manageCookiesLabel ?? null
  };
  const captchaData = useCaptcha();
  const MemoizedSideBar = React.memo(SideBar);
  const onSdkLoad = async () => {
    try {
      const env = SDK_ENV[process.env.BUILD_ENV as keyof typeof SDK_ENV];
      const sdk = await window.__ArenaApi__?.getInstance(env);
      if (sdk) {
        sdkLoaded(sdk);
        const url = buildTheySellLibPath(process.env.VIDEO_ADS, arenaData.theySellAd?.videoAdUrl || '');
        logger.info('Loaded videoAdsUrl', process.env.VIDEO_ADS, arenaData.theySellAd?.videoAdUrl || '', url);
        sdk?.ads?.setVideoAdsUrl(url);
      }
    } catch (e) {
      logger.error(e);
    }
  };
  return <>
			<Script id='arena-sdk' strategy='afterInteractive' src='https://developers.arkadium.com/cdn/sdk/v2/arena-sdk.js' onLoad={onSdkLoad} data-sentry-element="Script" data-sentry-source-file="view.tsx"></Script>
			{browserTrackingConsent !== null && <ExternalScripts scripts={externalScripts} onLoad={onScriptLoad} readyToLoadMicrosoft1DS={readyToLoadMicrosoft1DS} />}
			<div className={classNames('data-wrapper', sidebarHiddenClosedState ? 'sidebar-collapsed-hidden' : '')} data-theme={arenaData?.layout?.themeLayout?.theme} data-round_corners={arenaData?.layout?.themeLayout?.roundCorners} data-carousel_arrows_visible={arenaData?.layout?.themeLayout?.carouselArrowsVisible} data-layout={arenaData?.layout?.themeLayout?.layout} data-font={arenaData?.layout?.themeLayout?.fontFamily} data-mode={arenaData?.layout?.themeLayout?.mode} data-max_width={arenaData?.layout?.themeLayout?.arenaMaxWidth}>
				<AnalyticsContextProvider value={{
        AITracks,
        AIReadyAsDep
      }} data-sentry-element="AnalyticsContextProvider" data-sentry-source-file="view.tsx">
					<div className={'viewTopContainer'}>
						<NotificationBar data-sentry-element="NotificationBar" data-sentry-source-file="view.tsx" />
						<div className={'viewNavigationBars'}>
							{arenaData.layout?.topBar &&
            // if we just put AIReadyAsDep as condition, this will be rendered after child layout, but now it will just update AITracks
            <TopBar authFeatureToggle={authFeatureToggle} zIndexStartVal={zIndexStartVal} attributes={topbarAttributes} key={`topbar-${AIReadyAsDep}`} isAuthFeatureEnabled={isAuthFeatureEnabled} isLoggedIn={isLoggedIn} onLogout={onLogout} username={userData?.name} isLoginModalOpen={isLoginModalOpen} setIsLoginModalOpen={setIsLoginModalOpen} captchaData={captchaData} onLoginSuccess={handleLoginSuccess} domain={domain} />
            // key-change to update memoized component
            }
							{showSidebar && <MemoizedSideBar zIndexStartVal={zIndexStartVal} attributes={sidebarAttributes} key={`sidebar-${AIReadyAsDep}`} />}
						</div>
					</div>
					{children}
					<Footer attributes={footerData} data-sentry-element="Footer" data-sentry-source-file="view.tsx" />
					{isTrackingEnabled && <Pixel id='msnPixel' src={'https://c.msn.com/c.gif'} />}
				</AnalyticsContextProvider>
			</div>
		</>;
};