'use client';

import { ProfileText } from '@/components/Profile/Components/ProfileText/ProfileText';
import React from 'react';
import { DeleteProfileModal } from './DeleteModal';
export type ProfileDeleteAccountProps = {
  cssClassCannotBeUndone: string;
  cssClassDeleteButton: string;
};
export const ProfileDeleteAccount = ({
  cssClassCannotBeUndone,
  cssClassDeleteButton
}: ProfileDeleteAccountProps) => {
  return <>
			<ProfileText text={`Deleting your profile will remove all of your content and data associated with it.`} data-sentry-element="ProfileText" data-sentry-source-file="ProfileDeleteAccount.tsx" />
			<ProfileText text={`This cannot be undone.`} addClasses={[cssClassCannotBeUndone]} data-sentry-element="ProfileText" data-sentry-source-file="ProfileDeleteAccount.tsx" />
			<DeleteProfileModal cssClassDeleteButton={cssClassDeleteButton} data-sentry-element="DeleteProfileModal" data-sentry-source-file="ProfileDeleteAccount.tsx" />
		</>;
};