import { Control, Field, Label } from '@radix-ui/react-form';
import { PasswordRequirements } from '@/components/Auth/PasswordRequirements/PasswordRequirements';
import formStyles from '@/features/auth/form.styles.module.css';
import styles from './styles.module.css';
import classNames from 'classnames';
import { useState } from 'react';
import { StaticImage } from '@/components/StaticImage';
export const PasswordField = ({
  name,
  label,
  withRequirements,
  onUpdate,
  notification,
  invalid
}: {
  name: string;
  withRequirements: boolean;
  invalid: boolean;
  notification?: React.ReactNode;
  label?: string;
  onUpdate?: (value: string) => void;
}) => {
  const [pass, setPass] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggle = () => setIsPasswordVisible(prev => !prev);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPass(e.target.value);
    onUpdate?.(e.target.value);
  };
  return <Field className={classNames(formStyles.Field, styles.PasswordField, {
    [formStyles.Invalid]: invalid
  })} name={name} onChange={e => {
    e.preventDefault();
  }} data-sentry-element="Field" data-sentry-component="PasswordField" data-sentry-source-file="PasswordField.tsx">
			<Label className={formStyles.Label} data-sentry-element="Label" data-sentry-source-file="PasswordField.tsx">{label ?? 'Password'}</Label>
			<div className={styles.FieldInputWrapper}>
				<Control asChild data-sentry-element="Control" data-sentry-source-file="PasswordField.tsx">
					<input required className={classNames(formStyles.Input, formStyles.WithIcon)} name={name} type={isPasswordVisible ? 'text' : 'password'} onChange={onChange} />
				</Control>
				{withRequirements && <PasswordRequirements password={pass} />}
				<StaticImage className={styles.FieldInputIconImage} onClick={toggle} src={isPasswordVisible ? '/assets/general/eye.svg' : '/assets/general/eye-off.svg'} alt='eye-icon' width={24} height={24} data-sentry-element="StaticImage" data-sentry-source-file="PasswordField.tsx" />
			</div>
			{notification && <>{notification}</>}
		</Field>;
};