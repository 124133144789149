'use client';

import { Root, Field, Label, Control, Submit, Message } from '@radix-ui/react-form';
import formStyles from '../form.styles.module.css';
import { TCaptchaProps, TCommonAuthModalProps } from '../EntryForm';
import { GoogleAuthButton } from '@/components/Auth/GoogleAuthButton';
import { FacebookAuthButton } from '@/components/Auth/FacebookAuthButton';
import { AuthError } from '@/components/Auth/AuthError/AuthError';
import classNames from 'classnames';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
import { AuthType } from '@/root/libs/api/auth';
import { Description, Title } from '@radix-ui/react-dialog';
import modalStyles from '@/components/Modal/styles.module.css';
import { StaticImage } from '@/components/StaticImage';
import { AuthNotificationSuccess } from '@/components/Auth/AuthNotificationSuccess/AuthNotificationSuccess';
import { ContinueButton } from '@/components/Auth/ContinueButton/ContinueButton';
import styles from './styles.module.css';
import { UserApiErrorV1 } from '@arkadium/eagle-user-client';
import { EAuthSteps } from '../EntryModal';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import { ResponseError } from '@/root/types/ResponseError';
export type TSignInUpEmailFormProps = {
  email: string;
  changeEmail: (email: string) => void;
  changeCurrentStep: (step: EAuthSteps) => void;
  emailConfirmState: 'success' | 'error' | null;
  authType: AuthType;
  changeAuthType: (authType: AuthType) => void;
  changeEmailConfirmState: (state: 'success' | 'error' | null) => void;
  onLoginSuccess: () => void;
  currentStep: EAuthSteps;
  byGoogle: boolean | null;
  byFacebook: boolean | null;
  byEmail: boolean | null;
} & TCommonAuthModalProps & TCaptchaProps;
export const SignInUpEmailForm = ({
  email,
  changeEmail,
  errors,
  changeErrors,
  changeErrorCode,
  loading,
  changeLoading,
  emailConfirmState,
  changeEmailConfirmState,
  changeCurrentStep,
  authType,
  changeAuthType,
  currentStep,
  onLoginSuccess,
  captchaToken,
  captchaMode,
  getCaptchaToken,
  clearCaptchaData,
  domainWithProtocol,
  byGoogle,
  byFacebook,
  byEmail
}: TSignInUpEmailFormProps) => {
  const descriptionItems = [{
    iconUrl: '/assets/heart.svg',
    iconAlt: 'heart icon',
    text: 'Receive free boosts'
  }, {
    iconUrl: '/assets/message-chat-circle.svg',
    iconAlt: 'chat icon',
    text: 'Be the first to know about new games'
  }, {
    iconUrl: '/assets/trophy.svg',
    iconAlt: 'trophy icon',
    text: 'Save and track your progress'
  }];
  const action = async (formData: FormData) => {
    changeErrors([]);
    changeEmailConfirmState(null);
    const data = Object.fromEntries(formData);
    const email = data.email as string;
    changeEmail(email);
    changeLoading(true);
    const res = await fetchInternalRoute<ResponseError | UserProfileDTO>(INTERNAL_ROUTES.CHECK_EMAIL, {
      method: 'POST',
      body: JSON.stringify({
        email
      })
    });
    // const res = await checkUserEmail({ email });
    changeLoading(false);
    if (res && 'errorCode' in res) {
      if (res.errorCode === UserApiErrorV1.UserNotFound) {
        changeCurrentStep(EAuthSteps.SIGN_UP_PASSWORD);
        return;
      }
      changeErrors(res.message as string[]);
      changeErrorCode(res.errorCode);
      return;
    }
    changeCurrentStep(EAuthSteps.SIGN_IN_PASSWORD);
  };
  const socialButtonProps = {
    authType,
    changeEmail,
    changeAuthType,
    onLoginSuccess,
    changeEmailConfirmState,
    changeErrors,
    changeErrorCode,
    currentStep,
    changeCurrentStep,
    changeLoading,
    captchaToken,
    captchaMode,
    getCaptchaToken,
    clearCaptchaData,
    domainWithProtocol
  };
  return <>
			{emailConfirmState === 'error' ? <AuthError error="Sorry, we couldn't confirm your email​" /> : <Title className={classNames(modalStyles.Title, styles.SignInUpTitle)}>Sign in or Sign up</Title>}
			{emailConfirmState === 'error' && authType === AuthType.Facebook ? <Description className={classNames(modalStyles.Description, styles.SignInUpDescription)}>
					<span className={styles.DescriptionItem}>
						To create a profile with your Facebook login, you must allow your email to be shared.
					</span>
					<span className={classNames(styles.DescriptionItem, styles.bold)}>Please try again.</span>
				</Description> : <Description className={classNames(modalStyles.Description, styles.SignInUpDescription)}>
					{descriptionItems.map((item, index) => <span key={index} className={styles.DescriptionItem}>
							<StaticImage width={24} height={24} src={item.iconUrl} alt={item.iconAlt} />
							{item.text}
						</span>)}
				</Description>}
			{emailConfirmState === 'success' ? <AuthNotificationSuccess text='Email confirmation complete​' /> : null}
			<Root className={classNames(formStyles.Root, styles.SignInUpEmailForm)} onSubmit={async event => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      await action(data);
    }} data-sentry-element="Root" data-sentry-source-file="index.tsx">
				<div className={styles.SocialLogins}>
					{byGoogle && <GoogleAuthButton {...socialButtonProps} />}
					{byFacebook && <FacebookAuthButton {...socialButtonProps} />}
				</div>
				{byEmail && <>
						<Field className={classNames(formStyles.Field, styles.EmailField)} name='email'>
							<Label className={formStyles.Label}>Continue with email</Label>
							<Control asChild>
								<input required className={formStyles.Input} name='email' type='email' placeholder='your.email@mail.com' defaultValue={email} />
							</Control>
							<Message match='valueMissing'>
								<AuthError error='Please enter your email' />
							</Message>
							<Message match='typeMismatch'>
								<AuthError error='Please provide a valid email' />
							</Message>
							<AuthErrorList errors={errors} />
						</Field>
						<Submit asChild>
							<ContinueButton loading={loading} text='Continue' />
						</Submit>
					</>}
			</Root>
		</>;
};