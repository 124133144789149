'use client';

import { ProfileCaptcha } from '@/components/Profile/Components/ProfileCaptcha/ProfileCaptcha';
import style from './style.module.css';
import React, { SyntheticEvent, useState } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { ProfileText } from '@/components/Profile/Components/ProfileText/ProfileText';
import { EProfilePasswords } from '@/root/actions/profile/changeUserPassword.action';
import { StaticImage } from '@/components/StaticImage';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { ResponseError } from '@/root/types/ResponseError';
export type TProfilePasswordProps = object;
export type TProfilePasswordsState = {
  [EProfilePasswords.CURRENT]: string;
  [EProfilePasswords.NEW]: string;
  [EProfilePasswords.CONFIRM]: string;
};
export const PasswordChange = (props: TProfilePasswordProps) => {
  const [pending, setPending] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const action = async (formData: FormData) => {
    setPending(true);
    const data = Object.fromEntries(formData) as TProfilePasswordsState;
    fetchInternalRoute<void | ResponseError>(INTERNAL_ROUTES.CHANGE_PASSWORD, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(res => {
      if (!res) {
        setError('');
        return;
      }
      setError(res.message[0] as string);
      return;
    }).finally(() => {
      setPending(false);
    });
  };
  return <form action={action} className={style.PasswordForm} data-sentry-component="PasswordChange" data-sentry-source-file="PasswordChange.tsx">
			<PasswordInput name={EProfilePasswords.CURRENT} label='Current password' testId='profile-password-current' placeholder='your current password' disabled={pending} onIconClick={(ev: SyntheticEvent) => handleSwitchInputType(ev)} data-sentry-element="PasswordInput" data-sentry-source-file="PasswordChange.tsx" />
			<PasswordInput name={EProfilePasswords.NEW} label='New password' placeholder='your new password' testId='profile-password-new' disabled={pending} onIconClick={(ev: SyntheticEvent) => handleSwitchInputType(ev)} data-sentry-element="PasswordInput" data-sentry-source-file="PasswordChange.tsx" />
			<PasswordInput name={EProfilePasswords.CONFIRM} label='Confirm new password' placeholder='confirm new password' testId='profile-password-confirm' disabled={pending} onIconClick={(ev: SyntheticEvent) => handleSwitchInputType(ev)} data-sentry-element="PasswordInput" data-sentry-source-file="PasswordChange.tsx" />
			<ProfileCaptcha error={error} data-sentry-element="ProfileCaptcha" data-sentry-source-file="PasswordChange.tsx" />
			<div className={style.PasswordButtonWrapper}>
				<ButtonCallToAction text={'Save'} size='medium' styling={'outline'} addClass={style.__btnSavePassword} testId={`profile-button`} data-sentry-element="ButtonCallToAction" data-sentry-source-file="PasswordChange.tsx" />
				<Image src='/assets/other/recaptcha.png' alt='recaptcha icon' width={80} height={70} className={classNames(style.PasswordButtonCaptchaLogo, {
        [style.PasswordButtonCaptchaLogoOnError]: error
      })} data-sentry-element="Image" data-sentry-source-file="PasswordChange.tsx" />
			</div>
		</form>;
};
const handleSwitchInputType = (ev: SyntheticEvent) => {
  const icon = ev?.target as HTMLInputElement;
  const field = icon?.parentElement?.parentElement;
  const input = field?.querySelector('input');
  if (input?.type) {
    input.type = input.type === 'password' ? 'text' : 'password';
  }
};
const PasswordInput = ({
  name,
  label,
  placeholder,
  disabled,
  testId,
  onIconClick
}: {
  name: EProfilePasswords;
  label: string;
  placeholder: string;
  disabled: boolean;
  testId: string;
  onIconClick: (ev: SyntheticEvent) => void;
}) => <label className={style.Field} data-testid={testId} data-sentry-component="PasswordInput" data-sentry-source-file="PasswordChange.tsx">
		<ProfileText text={label} isBold={true} addClasses={[style.FieldLabel]} data-sentry-element="ProfileText" data-sentry-source-file="PasswordChange.tsx" />
		<input name={name} className={classNames(style.FieldInput)} type='password' placeholder={placeholder} disabled={disabled} />
		<div className={style.FieldInputIcon} onClick={onIconClick}>
			<StaticImage className={style.FieldInputIconImage} src='/assets/general/eye.svg' alt='input-icon' width={19.69} height={14} style={{
      cursor: 'pointer'
    }} data-sentry-element="StaticImage" data-sentry-source-file="PasswordChange.tsx" />
		</div>
	</label>;