import { profileActionUpdateUser } from '@/root/libs/api/profile';
import { z } from 'zod';
import { profileSchemaCheck } from '@/libs/utils/profileSchemaCheck';
import { FeatureType, ResponseError } from '@/types/ResponseError';
import { UserApiErrorV1 } from '@/root/libs/api/eagle-api-wrapper/api-gateway';

export enum EBirthdayErrors {
	REQUIRED = 'Birthday is required',
	INVALID = 'Invalid date',
	YOUNG = 'You must be at least 18 years old',
}

export const updateUserBirthday = async (birthday: string) => {
	const today = new Date();
	const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
	const schema = z
		.string()
		.min(1, { message: EBirthdayErrors.REQUIRED })
		.refine((dateString) => !isNaN(Date.parse(dateString)), {
			message: EBirthdayErrors.INVALID,
		}) // Ensure the value is a valid date
		.refine((dateString) => new Date(dateString) <= eighteenYearsAgo, {
			message: EBirthdayErrors.YOUNG,
		});
	const check = profileSchemaCheck(schema, birthday);
	if (check) {
		return {
			message: check,
			errorCode: UserApiErrorV1.InvalidRequestData,
			statusCode: 400,
			feature: FeatureType.PROFILE,
		} as ResponseError;
	}
	const res = await profileActionUpdateUser({
		birthday,
	});
	return res;
};
