'use client';

import React, { useEffect } from 'react';
import style from './style.module.css';
import { ProfileHeader } from '@/components/Profile/Components/ProfileHeader/ProfileHeader';
import classNames from 'classnames';
import { ProfileSection } from '@/components/Profile/Components/ProfileSection/ProfileSection';
import { ProfileAvatar } from '@/components/Profile/Components/ProfileAvatar/ProfileAvatar';
import { Username } from '@/components/Profile/Components/ProfileFields/Username';
import { Birthday } from '@/components/Profile/Components/ProfileFields/Birthday';
import { ProfileEmailConfirm } from '@/components/Profile/Components/ProfileEmailConfirm/ProfileEmailConfirm';
import { PasswordChange } from '@/components/Profile/Components/ProfileFields/PasswordChange';
import { EmailConsent } from '@/components/Profile/Components/ProfileFields/EmailConsent';
import { ProfileDeleteAccount } from '@/components/Profile/Components/ProfileDeleteAccount/ProfileDeleteAccount';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import { ResponseError } from '@/root/types/ResponseError';
export enum EAvatarsListItemType {
  Regular = 'regular',
  Premium = 'premium',
}
export type TAvatarsListItem = {
  id: number;
  image: {
    id: number;
    url: string;
  };
  type: EAvatarsListItemType;
};
export type TAvatarsLists = {
  regularAvatars: Array<TAvatarsListItem>;
  premiumAvatars: Array<TAvatarsListItem>;
};
export type ProfileProps = {
  profile: UserProfileDTO | ResponseError | null;
  avatars: TAvatarsLists;
  origin?: string;
};
export const Profile = ({
  profile,
  avatars,
  origin
}: ProfileProps) => {
  const [profileName, setProfileName] = React.useState<string>('');
  const [profileAvatar, setProfileAvatar] = React.useState<string>('');
  useEffect(() => {
    if (profile && 'name' in profile && !profileName) {
      setProfileName(profile.name);
      setProfileAvatar(profile.avatar);
    }
  }, [profile, profileName]);
  if (!profile || 'errorCode' in profile) {
    return null;
  }
  return <div className={style.Layout} data-testid={'profile-layout'} data-sentry-component="Profile" data-sentry-source-file="index.tsx">
			{/* PAGE HEADER AND NAV */}
			<ProfileHeader name={profileName} email={profile.email} avatar={profileAvatar} avatars={avatars} origin={origin} cssClassHeader={style.Header} cssClassButton={style.BackButton} data-sentry-element="ProfileHeader" data-sentry-source-file="index.tsx" />

			{/* PROFILE PAGE CONTENT */}
			<main className={classNames(style.Main)} data-testid='profile-main'>
				{/* USER DATA SECTION */}
				<ProfileSection title='My profile' data-sentry-element="ProfileSection" data-sentry-source-file="index.tsx">
					<div className={style.UserDataMain}>
						<ProfileAvatar avatars={avatars} avatar={profileAvatar} userDataStore={profile} mainStateUpdate={setProfileAvatar} size={80} isEditable={true} data-testid={`profile-avatar-field`} data-sentry-element="ProfileAvatar" data-sentry-source-file="index.tsx" />
						<Username name={profile.name} mainStateUpdate={setProfileName} data-sentry-element="Username" data-sentry-source-file="index.tsx" />
					</div>
					<Birthday birthday={profile.birthday} data-sentry-element="Birthday" data-sentry-source-file="index.tsx" />
					<ProfileEmailConfirm {...profile} data-sentry-element="ProfileEmailConfirm" data-sentry-source-file="index.tsx" />
				</ProfileSection>

				{/* PASSWORD CHANGE SECTION */}
				<ProfileSection title='Password Update' addClasses={[style.ProfileSectionPasswords]} data-sentry-element="ProfileSection" data-sentry-source-file="index.tsx">
					<PasswordChange data-sentry-element="PasswordChange" data-sentry-source-file="index.tsx" />
				</ProfileSection>

				{/* MAILING CONSENT SECTION */}
				<ProfileSection title='Newsletter Preferences' postTitleText={`Stay up to date on what's happening at Arkadium`} data-sentry-element="ProfileSection" data-sentry-source-file="index.tsx">
					<EmailConsent userDataStore={profile} data-sentry-element="EmailConsent" data-sentry-source-file="index.tsx" />
				</ProfileSection>

				{/* DELETE ACCOUNT SECTION */}
				<ProfileSection title='Delete Profile' data-sentry-element="ProfileSection" data-sentry-source-file="index.tsx">
					<ProfileDeleteAccount cssClassCannotBeUndone={style.__CannotBeUndone} cssClassDeleteButton={style.__btnDelete} data-sentry-element="ProfileDeleteAccount" data-sentry-source-file="index.tsx" />
				</ProfileSection>
			</main>
			<div className={style.Footer} data-testid='profile-footer' />
		</div>;
};