'use client';

import React from 'react';
import styles from './styles.module.css';
import { AuthText } from '@/components/Auth/AuthText/AuthText';
export type AuthNotificationSuccessProps = {
  text: string | null;
  stylePassed?: any;
};
export const AuthNotificationSuccess = ({
  text,
  stylePassed
}: AuthNotificationSuccessProps) => {
  return text && <AuthText addClasses={[styles.NotificationSuccessMsg]} text={text} stylePassed={stylePassed} testId={'notification-success'} />;
};