'use client';

import { Modal } from '@/components/Modal';
import { Description, Title } from '@radix-ui/react-dialog';
import { Root, Submit } from '@radix-ui/react-form';
import { useState } from 'react';
import formStyles from '@/features/auth/form.styles.module.css';
import styles from './styles.module.css';
import modalStyles from '@/components/Modal/styles.module.css';
import classNames from 'classnames';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { ResponseError } from '@/root/types/ResponseError';
import { usePathname, useRouter } from 'next/navigation';
import { logger } from '@/root/libs/utils/logger';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { getLocaleFromPathname } from '@/root/libs/utils/url';
type TProps = {
  cssClassDeleteButton: string;
};
export const DeleteProfileModal = (props: TProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const router = useRouter();
  const pathname = usePathname();
  const locale = getLocaleFromPathname(pathname);
  const {
    cssClassDeleteButton
  } = props;
  const action = async () => {
    const res = await fetchInternalRoute<void | ResponseError>(INTERNAL_ROUTES.PROFILE, {
      method: 'DELETE'
    });
    if (!res) {
      setModalOpen(false);
      router.push(`/${locale}?logout=true`);
      return;
    }
    if ('errorCode' in res) {
      logger.error(res);
      setErrors(res.message as string[]);
    }
  };
  const CustomButton = (props: {
    text: string;
    testId: string;
  }) => <ButtonCallToAction text={props.text} size='medium' styling='text' addClass={cssClassDeleteButton} testId={props.testId} data-sentry-element="ButtonCallToAction" data-sentry-component="CustomButton" data-sentry-source-file="DeleteModal.tsx" />;
  const Content = <Root className={classNames(formStyles.Root, styles.DeleteProfileModal)} onSubmit={async event => {
    event.preventDefault();
    await action();
  }}>
			<Title className={modalStyles.Title}>This cannot be undone.</Title>
			<Description className={modalStyles.Description}>
				Deleting your profile will remove all of your content and data associated with it.
			</Description>
			<Description className={modalStyles.Description}>Are you sure you want to delete your profile?</Description>
			<AuthErrorList errors={errors} />
			<div className={styles.ButtonBlock}>
				<ButtonCallToAction text={'Cancel'} size='medium' styling={'text'} onClick={() => setModalOpen(false)} />
				<Submit asChild>
					<CustomButton text={'Delete'} testId={'profile-delete-button-modal'} />
				</Submit>
			</div>
		</Root>;
  return <Modal open={modalOpen} onSetOpen={setModalOpen} ctaButtonText='' content={Content} customButton={<CustomButton text={'Yes, I want to delete my profile'} testId={'profile-delete-button'} />} data-sentry-element="Modal" data-sentry-component="DeleteProfileModal" data-sentry-source-file="DeleteModal.tsx"></Modal>;
};