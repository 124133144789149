'use client';

import React from 'react';
import style from './style.module.css';
import { ProfileError } from '@/components/Profile/Components/ProfileError/ProfileError';
import classNames from 'classnames';
export type ProfileCaptchaProps = {
  error?: string | null;
};
export const ProfileCaptcha = ({
  error
}: ProfileCaptchaProps) => {
  const isError = Boolean(error);
  return <div className={classNames(style.ProfileCaptcha, {
    [style.ProfileCaptchaError]: isError
  })} data-testid={`profile-captcha`} data-sentry-component="ProfileCaptcha" data-sentry-source-file="ProfileCaptcha.tsx">
			<div className={style.ProfileRecaptchaBlock}>{/*ProfileRecaptchaBlock here if needed*/}</div>
			<ProfileError error={error ?? 'No error'} data-sentry-element="ProfileError" data-sentry-source-file="ProfileCaptcha.tsx" />
		</div>;
};