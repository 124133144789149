import { Spinner } from '@/components/Spinner';
import { ESpinnerSize } from '@/components/Spinner/model';
import styles from './styles.module.css';
import classNames from 'classnames';
type TProps = {
  text?: string;
  size?: ESpinnerSize;
};
export const Loader = ({
  text,
  size
}: TProps) => {
  return <p className={classNames(styles.Loader)} data-sentry-component="Loader" data-sentry-source-file="index.tsx">
			<Spinner size={size ?? ESpinnerSize.SMALL} data-sentry-element="Spinner" data-sentry-source-file="index.tsx" />
			{text}
		</p>;
};