import { Dropdown } from '@/components/Dropdown';
import { getLocaleFromPathname } from '@/root/libs/utils/url';
import { useRouter } from 'next/navigation';
type TUserDropdownProps = {
  username: string | null | undefined;
  onLogout: () => void;
};
export type TProfileRoute = {
  label: string;
  route: string;
  iconPath: string;
};
export const profileRoutes: Array<TProfileRoute> = [{
  label: 'My profile',
  route: '/profile',
  iconPath: '/assets/myprofile-icon.svg'
}, {
  label: 'Log out',
  route: '/logout',
  iconPath: '/assets/logout-icon.svg'
}];
export const UserDropdown = ({
  username,
  onLogout
}: TUserDropdownProps) => {
  const router = useRouter();
  const onUpdate = (value: string) => {
    const route = profileRoutes.find(item => value.includes(item.label))?.route;
    const locale = getLocaleFromPathname(window.location.pathname);
    if (!route) return;
    if (route === '/logout') return onLogout();
    if (window.location.pathname.includes(route)) {
      return;
    }
    router.push(`/${locale}${route}`);
  };
  return <Dropdown username={username} items={profileRoutes} type={'user'} onUpdate={onUpdate} data-sentry-element="Dropdown" data-sentry-component="UserDropdown" data-sentry-source-file="index.tsx" />;
};