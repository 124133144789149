import { TProfilePasswordsState } from '@/components/Profile/Components/ProfileFields/PasswordChange';
import { EagleLoginService } from '@/libs/api/auth';
import { ChangePasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/change-password.dto';
import { UserApiErrorV1 } from '@arkadium/eagle-user-client';
import { FeatureType, ResponseError } from '@/root/types/ResponseError';

export enum EProfilePasswords {
	CURRENT = 'oldPassword',
	NEW = 'newPassword',
	CONFIRM = 'passwordConfirm',
}

const passMinLength = 8;

export enum EProfileErrors {
	REQUIRED = 'All fields are required',
	LENGTH = `Password should be minimum ${passMinLength} characters long`,
	CAPITAL = 'Password should contain at least 1 upper case letter',
	NOT_MATCH = 'Passwords do not match',
}

export const changeUserPassword = async (passwordsData: TProfilePasswordsState) => {
	const message: EProfileErrors = checkPasswordsErrors(passwordsData);
	if (message) {
		return {
			message: [message],
			errorCode: UserApiErrorV1.InvalidRequestData,
			statusCode: 400,
			feature: FeatureType.PROFILE,
		} as ResponseError;
	}
	const res = await EagleLoginService.changePassword({
		oldPassword: passwordsData[EProfilePasswords.CURRENT],
		newPassword: passwordsData[EProfilePasswords.NEW],
	} as ChangePasswordDTO);
	return res;
};

function checkPasswordsErrors(newVal: TProfilePasswordsState): EProfileErrors {
	const newPassword = newVal[EProfilePasswords.NEW];
	const confirmPassword = newVal[EProfilePasswords.CONFIRM];

	if (!Object.values(newVal).every((val) => typeof val === 'string' && val !== '')) {
		return EProfileErrors.REQUIRED;
	}

	if (newPassword.length < passMinLength) {
		return EProfileErrors.LENGTH;
	}

	if (!/[A-Z]/.test(newPassword)) {
		return EProfileErrors.CAPITAL;
	}

	if (newPassword !== confirmPassword) {
		return EProfileErrors.NOT_MATCH;
	}

	return '' as EProfileErrors;
}
