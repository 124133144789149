'use client';

import React, { useEffect } from 'react';
import style from './style.module.css';
import { ProfileText } from '@/components/Profile/Components/ProfileText/ProfileText';
import { isServer } from '@/libs/utils/isServer';
import { qaSearchParams } from '@/libs/utils/constants';
export type ProfileErrorProps = {
  error: string | null;
  stylePassed?: any;
};
const checkSearchForError = () => !isServer && window.location.search.match(new RegExp(qaSearchParams.PROFILE_ERROR, 'g'));
const getErrorFromSearch = () => !isServer && window.location.search.split(`${qaSearchParams.PROFILE_ERROR}=`)[1] || 'Error: test error message!';
export const ProfileError = ({
  error,
  stylePassed
}: ProfileErrorProps) => {
  const [errorState, setErrorState] = React.useState<string | null>(error); // for QA purposes
  useEffect(() => {
    !error && !errorState && checkSearchForError() && setErrorState(getErrorFromSearch());
    (error || errorState) && setErrorState(error);
  }, [isServer, error]);
  return errorState && <ProfileText addClasses={[style.ErrorMsg]} text={`${errorState}`} stylePassed={stylePassed} testId={'error'} />;
};