'use client';

import React, { useEffect, useState } from 'react';
import { useCaptcha } from '../recaptcha';
import { EntryForm } from './EntryForm';
import { Modal } from '@/components/Modal';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { EmailCodes } from '@/root/libs/utils/constants';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { ResponseError } from '@/root/types/ResponseError';
export enum EAuthSteps {
  SIGN_IN_UP_EMAIL = 'SIGN_IN_UP_EMAIL',
  SIGN_IN_UP_RESEND_EMAIL = 'SIGN_IN_UP_RESEND_EMAIL',
  GOOGLE_AUTH = 'GOOGLE_AUTH',
  FACEBOOK_AUTH = 'FACEBOOK_AUTH',
  SIGN_UP_PASSWORD = 'SIGN_UP_PASSWORD',
  SIGN_IN_PASSWORD = 'SIGN_IN_PASSWORD',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  EMAIL_SENT_FORM = 'EMAIL_SENT_FORM',
  RESEND_CONFIRMATION_EMAIL = 'RESEND_CONFIRMATION_EMAIL',
  RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM',
  RESET_PASSWORD_FORM_SUCCESS = 'RESET_PASSWORD_FORM_SUCCESS',
  THANK_YOU_FORM = 'THANK_YOU_FORM',
}
export const EntryModal = (props: {
  open: boolean;
  onSetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ctaButtonText: string;
  captchaData: ReturnType<typeof useCaptcha>;
  onLoginSuccess: () => void;
  domain: string;
  ctaStyling?: any;
  byGoogle: boolean | null;
  byFacebook: boolean | null;
  byEmail: boolean | null;
}) => {
  const searchParams = useSearchParams();
  const [emailConfirmState, setEmailConfirmState] = useState<'success' | 'error' | null>(null);
  const [resetPasswordRecoveryCode, setResetPasswordRecoveryCode] = useState<string>('');
  const router = useRouter();
  const pathname = usePathname();
  const [currentStep, setCurrentStep] = useState<EAuthSteps>(EAuthSteps.SIGN_IN_UP_EMAIL);
  useEffect(() => {
    const removeQueryParam = (param: string) => {
      const nextSearchParams = new URLSearchParams(searchParams.toString());
      nextSearchParams.delete(param);
      const newPathname = nextSearchParams.toString() ? `${pathname}?${nextSearchParams.toString()}` : pathname;
      router.replace(newPathname);
    };
    const confirmationCode = searchParams.get(EmailCodes.REGISTER_CONFIRMATION_CODE);
    const resetPasswordCode = searchParams.get(EmailCodes.RESET_PASSWORD_CODE);
    if (confirmationCode) {
      (async () => {
        const res = await fetchInternalRoute<void | ResponseError>(INTERNAL_ROUTES.CONFIRM_USER, {
          method: 'POST',
          body: JSON.stringify({
            code: confirmationCode
          })
        });
        // const res = await confirmUserRegistration({ code: confirmationCode });
        if (res && 'errorCode' in res) {
          setEmailConfirmState('error');
          return;
        }
        setEmailConfirmState('success');
        props.onSetOpen(true);
        removeQueryParam(EmailCodes.REGISTER_CONFIRMATION_CODE);
      })();
    }
    if (resetPasswordCode) {
      props.onSetOpen(true);
      setCurrentStep(EAuthSteps.RESET_PASSWORD_FORM);
      setResetPasswordRecoveryCode(resetPasswordCode);
      removeQueryParam(EmailCodes.RESET_PASSWORD_CODE);
    }
  }, [pathname, props, router, searchParams]);
  const BACK_BUTTON_STATES = [EAuthSteps.SIGN_UP_PASSWORD, EAuthSteps.RESEND_CONFIRMATION_EMAIL, EAuthSteps.SIGN_IN_PASSWORD, EAuthSteps.FORGOT_PASSWORD];
  const clearCaptchaData = props.captchaData.clearCaptchaData;
  const onBackButtonClick = () => {
    clearCaptchaData();
    if (currentStep === EAuthSteps.SIGN_UP_PASSWORD || currentStep === EAuthSteps.SIGN_IN_PASSWORD || currentStep === EAuthSteps.RESEND_CONFIRMATION_EMAIL) {
      setCurrentStep(EAuthSteps.SIGN_IN_UP_EMAIL);
    }
    if (currentStep === EAuthSteps.FORGOT_PASSWORD) {
      setCurrentStep(EAuthSteps.SIGN_IN_PASSWORD);
    }
  };
  const onAuthModalClose = () => {
    setCurrentStep(EAuthSteps.SIGN_IN_UP_EMAIL);
    clearCaptchaData();
    setEmailConfirmState(null);
  };
  return <Modal isBackButtonShown={BACK_BUTTON_STATES.includes(currentStep)} onBackButtonClick={onBackButtonClick} open={props.open} onSetOpen={props.onSetOpen} ctaButtonText={props.ctaButtonText} onClose={onAuthModalClose} content={<EntryForm emailConfirmState={emailConfirmState} domain={props.domain} captchaData={props.captchaData} onLoginSuccess={props.onLoginSuccess} currentStep={currentStep} changeCurrentStep={setCurrentStep} resetPasswordRecoveryCode={resetPasswordRecoveryCode} changeEmailConfirmState={setEmailConfirmState} byGoogle={props.byGoogle} byFacebook={props.byFacebook} byEmail={props.byEmail} />} ctaStyling={props.ctaStyling} data-sentry-element="Modal" data-sentry-component="EntryModal" data-sentry-source-file="EntryModal.tsx" />;
};