import { AuthProvider, OAuthUserResponse, TCaptchaProps, TCommonAuthModalProps } from '@/features/auth/EntryForm';
import { EAuthSteps } from '@/features/auth/EntryModal';
import { RECAPTCHA_ACTIONS, RECAPTCHA_MODES } from '@/features/recaptcha';
import { screenSizes } from '@/libs/utils/screensize';
import { useScreenSize } from '@/libs/utils/useScreensize';
import { AuthType } from '@/root/libs/api/auth';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { UserApiErrorV1 } from '@/root/libs/api/eagle-api-wrapper/api-gateway';
import { authUserViaFacebook, FacebookLoginDTO } from '@/root/libs/api/login';
import { resendEmail } from '@/root/libs/api/register';
import { ResponseError } from '@/root/types/ResponseError';
import { AuthResponsePayloadDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/auth-response.dto';
import { useCallback, useEffect, useRef, useState } from 'react';
const RENDER_FB_BUTTON = 'RENDER_FB_BUTTON';
const FACEBOOK_AUTH_EVENT_TYPE = 'update-user-info';
type TProps = {
  authType: AuthType;
  changeEmail: (email: string) => void;
  changeAuthType: (authType: AuthType) => void;
  onLoginSuccess: () => void;
  changeEmailConfirmState: (state: 'success' | 'error' | null) => void;
  currentStep: EAuthSteps;
  changeCurrentStep: (step: EAuthSteps) => void;
} & Omit<TCommonAuthModalProps, 'loading' | 'errors'> & TCaptchaProps;
export const FacebookAuthButton = ({
  changeEmail,
  changeAuthType,
  onLoginSuccess,
  changeEmailConfirmState,
  changeErrors,
  changeErrorCode,
  currentStep,
  changeCurrentStep,
  changeLoading,
  captchaToken,
  captchaMode,
  getCaptchaToken,
  clearCaptchaData,
  domainWithProtocol
}: TProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isButtonLoad, setIsButtonLoad] = useState<boolean>(false);
  const authButtonUrl = process.env.FACEBOOK_AUTH_BUTTON_URL;
  const screenSize = useScreenSize();
  const [socialUser, setSocialUser] = useState<OAuthUserResponse | null>(null);
  const eventType = AuthProvider.facebook;
  useEffect(() => {
    const onLoginMessage = (message: MessageEvent) => {
      const event = message.data;
      if (!event) {
        return;
      }
      switch (event.action) {
        case FACEBOOK_AUTH_EVENT_TYPE:
          setSocialUser(event.data);
          changeAuthType(AuthType.Facebook);
          changeErrors([]);
          changeErrorCode(null);
          if (!event.data.email) {
            changeEmailConfirmState('error');
            return;
          }
          changeEmailConfirmState(null);
          changeLoading(true);
          changeCurrentStep(EAuthSteps.FACEBOOK_AUTH);
          getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
          break;
        default:
          break;
      }
    };
    window.addEventListener('message', onLoginMessage);
    return () => window.removeEventListener('message', onLoginMessage);
  }, [changeAuthType, changeCurrentStep, changeEmailConfirmState, changeErrorCode, changeErrors, changeLoading, getCaptchaToken]);
  const getLoginData = useCallback((user: OAuthUserResponse, captchaToken: string, captchaMode: RECAPTCHA_MODES | undefined, domainWithProtocol: string) => {
    const loginData: FacebookLoginDTO & {
      authProviderAppId: string;
    } = {
      token: user.access_token,
      email: user.email ?? null,
      emailRetrievalConsent: false,
      registrationPlaceUrl: domainWithProtocol,
      registrationAppId: new URL(domainWithProtocol).hostname,
      authProviderAppId: '',
      //TODO: switch to dynamic facebookAppId from arena config
      captchaToken,
      ...(captchaMode ? {
        captchaMode
      } : {})
    };
    return loginData;
  }, []);
  useEffect(() => {
    if (iframeRef?.current && isButtonLoad) {
      iframeRef.current.contentWindow?.postMessage({
        type: RENDER_FB_BUTTON,
        payload: {
          innerWidth: window.innerWidth
        }
      }, authButtonUrl);
    }
  }, [authButtonUrl, isButtonLoad]);
  useEffect(() => {
    if (captchaToken && currentStep === EAuthSteps.FACEBOOK_AUTH) {
      (async () => {
        const loginData = getLoginData(socialUser as OAuthUserResponse, captchaToken as string, captchaMode, domainWithProtocol) as FacebookLoginDTO;
        const res = await fetchInternalRoute<ResponseError | AuthResponsePayloadDto>(INTERNAL_ROUTES.AUTH_BY_FACEBOOK, {
          method: 'POST',
          body: JSON.stringify(loginData)
        });
        // const res = await authUserViaFacebook(loginData);
        changeLoading(false);
        if (res && 'errorCode' in res) {
          changeErrors(res.message as string[]);
          changeErrorCode(res.errorCode);
          if (res.errorCode === UserApiErrorV1.EmailDoesNotSpecified) {
            changeEmailConfirmState('error');
          }
          if (res.errorCode === UserApiErrorV1.UserNotConfirmedError) {
            changeEmail(loginData.email);
            changeLoading(true);
            changeCurrentStep(EAuthSteps.SIGN_IN_UP_RESEND_EMAIL);
            getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
          }
          return;
        }
        onLoginSuccess();
        clearCaptchaData();
      })();
    }
  }, [captchaMode, captchaToken, changeCurrentStep, changeEmail, changeEmailConfirmState, changeErrorCode, changeErrors, changeLoading, clearCaptchaData, domainWithProtocol, eventType, getCaptchaToken, getLoginData, onLoginSuccess, socialUser, currentStep]);
  useEffect(() => {
    if (captchaToken && currentStep === EAuthSteps.SIGN_IN_UP_RESEND_EMAIL) {
      (async () => {
        const res = await resendEmail({
          email: (socialUser as OAuthUserResponse).email,
          authType: AuthType.Facebook,
          resendConfirmationFormUrl: domainWithProtocol,
          captchaToken,
          ...(captchaMode ? {
            captchaMode
          } : {})
        });
        if (res && 'errorCode' in res) {
          changeErrors(res.message as string[]);
          changeErrorCode(res.errorCode);
          return;
        }
        clearCaptchaData();
        changeCurrentStep(EAuthSteps.EMAIL_SENT_FORM);
      })();
    }
  }, [captchaMode, captchaToken, changeCurrentStep, changeErrorCode, changeErrors, clearCaptchaData, currentStep, domainWithProtocol, socialUser]);
  return <iframe ref={iframeRef} onLoad={() => setIsButtonLoad(true)} src={authButtonUrl} title='facebook button iframe' id='facebook-button-iframe' height='40px' width={screenSize === screenSizes.xxs ? '360px' : '400px'} data-sentry-component="FacebookAuthButton" data-sentry-source-file="index.tsx" />;
};