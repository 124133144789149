'use client';

import React from 'react';
import style from './style.module.css';
export const ProfileTitle = ({
  title
}: {
  title: string;
}) => {
  return <h2 className={style.Title} data-testid={`profile-title`} data-sentry-component="ProfileTitle" data-sentry-source-file="ProfileTitle.tsx">
			{title}
		</h2>;
};