'use client';

import React, { useState } from 'react';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import style from './style.module.css';
import { ProfileAvatarSelection } from '@/components/Profile/Components/ProfileAvatarSelection/ProfileAvatarSelection';
import classNames from 'classnames';
import Image from 'next/image';
import { TAvatarsLists } from '@/components/Profile';
export type ProfileUserDataAvatarProps = {
  size: number;
  isEditable?: boolean;
  userDataStore?: UserProfileDTO;
  mainStateUpdate?: (data: string) => void;
  avatar?: string;
  action?: (avatarId: string) => Promise<string>;
  avatars?: TAvatarsLists;
};
export type TAllAvatarsListItem = {
  id: number | null;
  imgUrl: string;
  imgId: string;
  isPremium: boolean;
};
export const DEFAULT_USER_AVATAR = '/assets/profile_avatar_blank.png';
export const ProfileAvatar = (props: ProfileUserDataAvatarProps) => {
  const [isSelectAvatar, setIsSelectAvatar] = useState<boolean>(false);
  const size = props.size ?? 80;
  const avatarFinal = props.avatar || props.userDataStore?.avatar || DEFAULT_USER_AVATAR;
  return <>
			<div className={classNames(style.Avatar, {
      [style.__AvatarEditable]: props.isEditable
    })} data-selected={isSelectAvatar} style={{
      width: `${size}px`,
      height: `${size}px`,
      minWidth: `${size}px`,
      minHeight: `${size}px`
    }} data-testid={`profile-avatar-main`}>
				<Image src={avatarFinal} alt='User avatar' width={size} height={size} className={classNames(style.AvatarImage, style.__AvatarImageUserdata)} onClick={() => props.isEditable && setIsSelectAvatar(true)} data-sentry-element="Image" data-sentry-source-file="ProfileAvatar.tsx" />
			</div>
			{props.isEditable && isSelectAvatar && props.avatars && props.userDataStore && <ProfileAvatarSelection avatars={props.avatars} setIsSelectAvatar={setIsSelectAvatar} action={props.action} userDataStore={props.userDataStore} mainStateUpdate={props.mainStateUpdate} />}
		</>;
};