'use client';

import { ButtonCallToAction } from '@/components/ButtonCallToAction';
import { ProfileAsideBlock } from '@/components/Profile/Components/ProfileAsideBlock/ProfileAsideBlock';
import React from 'react';
import { LocalizedLink } from '@/components/Link';
import { TAvatarsLists } from '@/components/Profile';
import { BackButton } from '@/components/BackButton';
export type ProfileProps = {
  name: string;
  email: string;
  avatar: string;
  avatars: TAvatarsLists;
  origin?: string;
  cssClassHeader?: string;
  cssClassButton?: string;
};
export const ProfileHeader = ({
  name,
  email,
  avatar,
  avatars,
  origin,
  cssClassHeader,
  cssClassButton
}: ProfileProps) => <div className={cssClassHeader} data-sentry-component="ProfileHeader" data-sentry-source-file="ProfileHeader.tsx">
		<LocalizedLink href={origin ?? '/public'} target='_self' data-sentry-element="LocalizedLink" data-sentry-source-file="ProfileHeader.tsx">
			<BackButton addClass={cssClassButton} testId={'profile-back-button'} data-sentry-element="BackButton" data-sentry-source-file="ProfileHeader.tsx" />
		</LocalizedLink>
		<ProfileAsideBlock name={name} email={email} avatar={avatar} avatars={avatars} data-sentry-element="ProfileAsideBlock" data-sentry-source-file="ProfileHeader.tsx" />
	</div>;