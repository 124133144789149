import { Description, Title } from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { EmailSentForm } from '../EmailSentForm';
import { TCaptchaProps, TCommonAuthModalProps } from '../EntryForm';
import modalStyles from '@/components/Modal/styles.module.css';
import styles from './styles.module.css';
import { AuthType } from '@/root/libs/api/auth';
import { EAuthSteps } from '../EntryModal';
import { UserApiErrorV1 } from '@/root/libs/api/eagle-api-wrapper/api-gateway';
type TProps = {
  email: string;
  changeCurrentStep: (step: EAuthSteps) => void;
  authType: AuthType;
} & TCommonAuthModalProps & TCaptchaProps;
export const ThankYouForm = ({
  email,
  loading,
  errors,
  changeErrors,
  changeErrorCode,
  changeCurrentStep,
  changeLoading,
  captchaToken,
  captchaMode,
  getCaptchaToken,
  clearCaptchaData,
  domainWithProtocol,
  authType
}: TProps) => {
  return <>
			<Title className={classNames(modalStyles.Title, styles.ThankYouTitle)} data-sentry-element="Title" data-sentry-source-file="index.tsx">Thank you</Title>
			<Description className={styles.ThankYouDescription} data-sentry-element="Description" data-sentry-source-file="index.tsx">
				We sent an email to {email} with instructions
			</Description>
			<Description className={styles.ThankYouDescription} data-sentry-element="Description" data-sentry-source-file="index.tsx">
				Can’t find the email? Check your Spam folder or resend it
			</Description>
			<EmailSentForm isThankYouForm={true} email={email} loading={loading} errors={errors} changeErrors={changeErrors} changeErrorCode={changeErrorCode} changeLoading={changeLoading} changeCurrentStep={changeCurrentStep} domainWithProtocol={domainWithProtocol} captchaToken={captchaToken} captchaMode={captchaMode} getCaptchaToken={getCaptchaToken} clearCaptchaData={clearCaptchaData} authType={authType} data-sentry-element="EmailSentForm" data-sentry-source-file="index.tsx" />
		</>;
};