import React from 'react';
import { Root, Portal, Overlay, Content, Close, Trigger } from '@radix-ui/react-dialog';
import styles from './styles.module.css';
import { StaticImage } from '../StaticImage';
import classNames from 'classnames';
import { BackButton } from '@/components/BackButton';
import { Button } from '../Button';
import { ButtonOverride } from '@/root/libs/api/arena-data';
export type TBaseModalProps = {
  open: boolean;
  onSetOpen: (open: boolean) => void;
};
type TModalProps = TBaseModalProps & {
  ctaButtonText: string;
  content: React.JSX.Element;
  isBackButtonShown?: boolean;
  onBackButtonClick?: () => void;
  onClose?: () => void;
  ctaStyling?: ButtonOverride[] | [];
  customButton?: React.JSX.Element;
};

//TODO: revise react.memo here
const Modal = React.memo(({
  onSetOpen,
  open,
  ctaButtonText,
  content,
  ctaStyling,
  isBackButtonShown,
  onBackButtonClick,
  onClose,
  customButton
}: TModalProps) => {
  const iconUrl = '/assets/user-02.svg';
  return <Root open={open} onOpenChange={onSetOpen}>
				<Trigger asChild className={styles.Trigger}>
					<div className={styles.ButtonWrapper}>
						{ctaButtonText && !customButton ? <Button overrides={ctaStyling} className={styles.Button}>
								<StaticImage src={iconUrl} alt={ctaButtonText} width={24} height={24} />
								<span>{ctaButtonText}</span>
							</Button> : customButton}
					</div>
				</Trigger>
				<Portal>
					<Overlay className={styles.Overlay} />

					<Content className={styles.Content}>
						<div className={classNames(styles.TopButtons, {
          [styles.isBackButtonShown]: isBackButtonShown
        })}>
							{isBackButtonShown ? <BackButton addClass={classNames(styles.BackButton)} testId={'modal-back-button'} onClick={onBackButtonClick} /> : null}
							<Close asChild>
								<button className={styles.Close} aria-label='Close' onClick={onClose}>
									<StaticImage className={styles.CloseIcon} src={'/assets/x-close.svg'} alt={'Close'} width={40} height={40} />
								</button>
							</Close>
						</div>
						<div className={styles.MainContent}>{content}</div>
					</Content>
				</Portal>
			</Root>;
});
Modal.displayName = 'Modal';
export { Modal };