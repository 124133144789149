import { ZodError, ZodSchema } from 'zod';
import { logger } from '@/libs/utils/logger';
import { EAvatarErrors } from '@/root/actions/profile/updateUserAvatar.action';

export const profileSchemaCheck = (schema: ZodSchema, val: any): void | string => {
	try {
		schema.parse(val);
	} catch (error) {
		const err = (error as ZodError)?.flatten?.()?.formErrors?.join?.('\n') ?? EAvatarErrors.FALLBACK;
		logger.error(err);
		return err;
	}
};
