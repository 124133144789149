'use client';

import React from 'react';
import { ProfileTitle } from '@/components/Profile/Components/ProfileTitle/ProfileTitle';
import style from './style.module.css';
import { ProfileText } from '@/components/Profile/Components/ProfileText/ProfileText';
import classNames from 'classnames';
export type ProfileSectionProps = {
  title: string;
  children?: any;
  postTitleText?: string;
  addClasses?: string[];
};
export const ProfileSection = (props: ProfileSectionProps) => {
  return <section className={classNames(style.Section, ...(props.addClasses ?? []))} data-testid={`profile-section`} data-sentry-component="ProfileSection" data-sentry-source-file="ProfileSection.tsx">
			<ProfileTitle title={props.title} data-sentry-element="ProfileTitle" data-sentry-source-file="ProfileSection.tsx" />
			{props.postTitleText && <ProfileText text={props.postTitleText} addClasses={[style.__SectionTextAdditional]} />}
			{props.children}
		</section>;
};