'use client';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TSideBar } from './model';
import { ETabIndexesOrderingLevels } from '@/types/types';
import styles from './styles.module.css';
import Image from 'next/image';
import { useUnit } from 'effector-react';
import { $arenaDataStore } from '@/app.model';
import classNames from 'classnames';
import { useScreenSize } from '@/libs/utils/useScreensize';
import { EScreenSize } from '@/libs/utils/screensize';
import { useDeviceDetector } from '@/libs/utils/userAgentContext';
import { AnalyticsContext } from '@/features/arena-data/view';
import { useRouter } from 'next/navigation';
import { Search } from '@/features/autocomplete';
export const BURGER_INPUT_ID = 'sidebarToggle';
const BUTTON_ID = 'sidebarButton';
export const SideBar = ({
  zIndexStartVal,
  attributes
}: any) => {
  const props = attributes as TSideBar;
  const arenaData = useUnit($arenaDataStore);
  const router = useRouter();
  const [sideBarWithTopItems, setSideBarWithTopItems] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);
  const screenSize = useScreenSize();
  const [isShowIcons, setIsShowIcons] = useState<boolean>(false);
  const device = useDeviceDetector();
  const isFirefox = device?.browser?.name === 'Firefox';
  useEffect(() => {
    const getSideBarWithTopItems = () => window.innerWidth <= 1524 || arenaData.layout?.topBar === false;
    const handleTopLinks = () => setSideBarWithTopItems(getSideBarWithTopItems());
    handleTopLinks();
    window.addEventListener('resize', handleTopLinks);
    return () => {
      window.removeEventListener('resize', handleTopLinks);
    };
  }, [arenaData.layout?.topBar]);
  const {
    AITracks
  } = useContext(AnalyticsContext);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [isClick, setIsClick] = useState<boolean>(false);
  const {
    groups,
    topbarLinks
  } = props;
  useEffect(() => {
    const xsSmScreen = screenSize === EScreenSize.xs || screenSize === EScreenSize.sm;
    if (!xsSmScreen) {
      setIsShowIcons(true);
    }
    if (isSideMenuOpen) {
      setIsShowIcons(isSideMenuOpen);
    }
  }, [isSideMenuOpen, screenSize, isShowIcons]);
  const styleOverride = (pairs: any) => {
    const validPairs = pairs.filter(([, value]: any) => !!value);
    if (!validPairs?.length) {
      return {};
    }
    return validPairs.reduce((styleObj: any, [cssVar, value]: any) => {
      styleObj[cssVar] = value;
      return styleObj;
    }, {});
  };
  const sideBarButtonOverrides = styleOverride([['--icon-color', props.override_color_sidebar_toggle], ['--icon-background', props.override_color_sidebar_toggle_background]]);
  const sideBarOverrides = styleOverride([['--background-color', props.override_color_sidebar_background], ['--divider-color', props.override_color_sidebar_divider]]);
  const sideBarItemOverrides = styleOverride([['--normal-label-color', props.override_color_item_normal_label], ['--hover-background-color', props.override_color_item_hover_background], ['--hover-border-color', props.override_color_item_hover_border]]);
  const sidebarTrackAnalytics = async (ev: any, link: TSideBar['topbarLinks'][0] | undefined, i: string) => {
    // if the page moves away too quickly then analytics fails to finalize
    if (link?.target === '_self') {
      ev.preventDefault();
      await AITracks.sideNavClick(link?.label, i, true);
      link.href && router.push(link.href);
    } else {
      AITracks.sideNavClick(link?.label, i, true);
    }
  };
  const inputOnChange = (ev: any) => {
    if (document.activeElement?.classList.contains('search-bar')) {
      return;
    }
    const isChecked = ev?.currentTarget?.checked;
    setIsSideMenuOpen(isChecked);
  };
  const handleEnterToToggleMenu = (ev: any) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      setIsSideMenuOpen(prevState => !prevState);
    }
  };
  const inputOnFocus = () => setTimeout(() => typeof document !== 'undefined' && (document as any)?.getElementById?.(BURGER_INPUT_ID)?.focus?.(), 100); // this is to fix focus lost and not trackable on rerender
  const lastItemOnBlur = () => {
    const input: any = document?.getElementById?.(BURGER_INPUT_ID);
    if (input) {
      const logic = () => {
        input.checked = false;
        setIsSideMenuOpen(false);
      };
      if (!isFirefox) {
        logic();
      } else {
        // this is to fix EVO-1262. FF has different order of events on click (blur -> click -> focus) and we need to wait for click event to be processed to handle link click
        setTimeout(() => logic(), 200);
      }
    }
  };
  const noop = () => {};
  function blurSearchElement() {
    const active = document.activeElement;
    // Skip blurring if the active element is the search bar
    if (active?.classList.contains('test-search-bar')) {
      return;
    }
    if (active?.classList.contains('ark-ui-search-active')) {
      (active as HTMLInputElement).blur();
    }
  }
  useEffect(() => {
    isSideMenuOpen && !isClick && setTimeout(() => (document?.querySelector?.(`.sidebar .item`) as HTMLElement)?.focus?.(), 200);

    //setTimeout for iOS scrolling when focusing input - 400 is the CSS transition time
    setTimeout(() => document.addEventListener('scroll', blurSearchElement), 400);
    return () => {
      document.removeEventListener('scroll', blurSearchElement);
    };
  }, [isFocused, isSideMenuOpen]);
  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);
  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);
  return <>
			<div style={{
      zIndex: zIndexStartVal + 2
    }} className={classNames('ark-ui-sidebar', styles.sidebarContainer)} onClick={e => e.stopPropagation()}>
				<input aria-label='Toggle sidebar menu' id={BURGER_INPUT_ID} type='checkbox' className={styles.input} tabIndex={ETabIndexesOrderingLevels.SECOND} onChange={inputOnChange} onFocus={inputOnFocus} onMouseUp={() => setIsClick(true)} onKeyUp={() => setIsClick(false)} onKeyDown={handleEnterToToggleMenu} onClick={e => e.stopPropagation()} />
				<div className={classNames(BUTTON_ID, styles.sidebarButton)} style={sideBarButtonOverrides} tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}>
					<svg className='hamburger' width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' data-sentry-element="svg" data-sentry-source-file="index.tsx">
						<path d='M9 19.8848H16.8848M9 12H32.6545M9 27.7697H20.8273' stroke='var(--icon-color)' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' data-sentry-element="path" data-sentry-source-file="index.tsx" />
						<path d='M33.4688 28L29.6117 23.9808M30.9692 20.704C30.9295 23.2536 28.8505 25.288 26.3255 25.2479C23.8006 25.2078 21.7859 23.1085 21.8256 20.5589C21.8653 18.0093 23.9443 15.9749 26.4693 16.015C28.9942 16.055 31.0089 18.1544 30.9692 20.704Z' stroke='var(--icon-color)' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' data-sentry-element="path" data-sentry-source-file="index.tsx" />
					</svg>
					<svg className='close' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' data-sentry-element="svg" data-sentry-source-file="index.tsx">
						<path d='M18 6L6 18M6 6L18 18' stroke='var(--icon-color)' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' data-sentry-element="path" data-sentry-source-file="index.tsx" />
					</svg>
				</div>
				<nav id='sidebar' className={classNames('ark-ui-sidebar sidebar', styles.sidebar)} style={sideBarOverrides} onClick={e => e.stopPropagation()}>
					{topbarLinks?.length && <>
							<div className={`${styles.searchContainer} ${isFocused ? styles.focused : ''}`} tabIndex={isSideMenuOpen ? ETabIndexesOrderingLevels.FIRST : ETabIndexesOrderingLevels.NOINTERACTION} onClick={e => e.stopPropagation()} onFocus={e => e.stopPropagation()}>
								<Search className='search-bar' isFocused={isFocused} onFocus={e => {
              e.stopPropagation();
              handleFocus();
            }} onBlur={e => {
              e.stopPropagation();
              handleBlur();
            }} />
							</div>
							<div className={classNames('group', 'topbarLinks', 'mobile', styles.__visible)}>
								{topbarLinks.map((l, index) => <a key={index} className='item' href={l.href || ''} onClick={() => AITracks.topNavClick(l?.label, index, true)} target={l.target || '_self'} style={sideBarItemOverrides} tabIndex={isSideMenuOpen ? ETabIndexesOrderingLevels.SECOND : ETabIndexesOrderingLevels.NOINTERACTION}>
										<span className='label __max12ch'>{l?.label?.substring(0, 12) || ''}</span>
									</a>)}
							</div>
							<div className={classNames('divider', 'mobile', styles.__visible)}></div>
						</>}

					{groups.map((g, i) => <React.Fragment key={i}>
							<div className={`group sidebarLinks${g.withBackground ? ' bg' : ''}`}>
								{g.link && <a className='item heading' href={g.link.href || '#'} target={g.link.target || '_self'} tabIndex={g.link.href && isSideMenuOpen ? ETabIndexesOrderingLevels.OTHER_AUTO : ETabIndexesOrderingLevels.NOINTERACTION} style={sideBarItemOverrides} onClick={e => sidebarTrackAnalytics(e, g?.link, i.toString())}>
										{isShowIcons && g.icon && <Image width={24} height={24} loading='lazy' className='itemIcon' src={g.icon} alt='Thumbnail' tabIndex={ETabIndexesOrderingLevels.NOINTERACTION} />}
										<span className='label __max12ch' tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}>
											{g.link.label?.substring(0, 12) || ''}
										</span>
									</a>}

								{g.sidebar_items?.map((item, j) => <a key={j} className='item' href={item.link?.href || '#'} target={item.link?.target || '_self'} tabIndex={isSideMenuOpen ? ETabIndexesOrderingLevels.SECOND : ETabIndexesOrderingLevels.NOINTERACTION} onClick={e => sidebarTrackAnalytics(e, item?.link, `${i}-${j}`)} data-order={`${j}/${g.sidebar_items.length - 1} -> ${i}/${groups.length - 1}`} onBlur={isSideMenuOpen && i === groups.length - 1 && j === g.sidebar_items.length - 1 ? lastItemOnBlur : noop} style={sideBarItemOverrides}>
										{isShowIcons && item.icon && <Image width={24} height={24} loading='lazy' className='itemIcon' src={item.icon} aria-hidden='true' alt='Thumbnail' tabIndex={ETabIndexesOrderingLevels.NOINTERACTION} />}
										<span className='label __max12ch' tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}>
											{item.link?.label?.substring(0, 12) || ''}
										</span>
									</a>)}
							</div>
							{i < groups.length - 1 && <div className='divider'></div>}
						</React.Fragment>)}
				</nav>
			</div>

			<label htmlFor='sidebarToggle' className='invisibleOverlay' style={{
      zIndex: zIndexStartVal
    }} aria-label='Toggle sidebar menu' onClick={e => {
      // Prevent the sidebar from closing if the active element is the search bar
      const active = document.activeElement;
      if (active?.classList.contains('test-search-bar')) {
        e.stopPropagation(); // Stop propagation to prevent unwanted behavior
        return;
      }
    }}></label>
		</>;
};