'use client';

import { Root, Field, Label, Control, Submit, Message } from '@radix-ui/react-form';
import formStyles from '@/features/auth/form.styles.module.css';
import { AuthError } from '@/components/Auth/AuthError/AuthError';
import classNames from 'classnames';
import { AuthErrorList } from '@/components/Auth/AuthError/AuthErrorList';
import { Description, Title } from '@radix-ui/react-dialog';
import styles from './styles.module.css';
import modalStyles from '@/components/Modal/styles.module.css';
import { ContinueButton } from '@/components/Auth/ContinueButton/ContinueButton';
import { EAuthSteps } from '../EntryModal';
import { RECAPTCHA_ACTIONS } from '@/features/recaptcha';
import { requestResetPassword } from '@/root/libs/api/login';
import { useEffect } from 'react';
import { TCaptchaProps, TCommonAuthModalProps } from '../EntryForm';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { ResponseError } from '@/root/types/ResponseError';
export type TForgotPasswordFormProps = {
  email: string;
  changeCurrentStep: (step: EAuthSteps) => void;
  changeEmail: (email: string) => void;
} & TCommonAuthModalProps & TCaptchaProps;
export const ForgotPasswordForm = ({
  email,
  errors,
  loading,
  changeErrors,
  changeErrorCode,
  changeCurrentStep,
  changeLoading,
  captchaToken,
  captchaMode,
  getCaptchaToken,
  clearCaptchaData,
  domainWithProtocol,
  changeEmail
}: TForgotPasswordFormProps) => {
  const forgotPasswordFormAction = (formData: FormData) => {
    changeErrors([]);
    const data = Object.fromEntries(formData);
    const email = data.email as string;
    changeEmail(email);
    changeLoading(true);
    getCaptchaToken(RECAPTCHA_ACTIONS.PASSWORD_RESET);
  };
  useEffect(() => {
    if (captchaToken) {
      (async () => {
        const res = await fetchInternalRoute<ResponseError | undefined>(INTERNAL_ROUTES.RESET_PASSWORD, {
          method: 'POST',
          body: JSON.stringify({
            email,
            registrationPlaceUrl: domainWithProtocol,
            resetPasswordConfirmationFormUrl: domainWithProtocol,
            captchaToken,
            ...(captchaMode ? {
              captchaMode
            } : {})
          })
        });
        // const res = await requestResetPassword({
        // 	email,
        // 	registrationPlaceUrl: domainWithProtocol,
        // 	resetPasswordConfirmationFormUrl: domainWithProtocol,
        // 	captchaToken,
        // 	...(captchaMode ? { captchaMode } : {}),
        // });

        changeLoading(false);
        if (res && 'errorCode' in res) {
          changeErrors(res.message as string[]);
          changeErrorCode(res.errorCode);
          return;
        }
        clearCaptchaData();
        changeCurrentStep(EAuthSteps.THANK_YOU_FORM);
      })();
    }
  }, [captchaToken, changeLoading, clearCaptchaData, changeCurrentStep, email, domainWithProtocol, captchaMode, changeErrors, changeErrorCode]);
  return <>
			<Title className={classNames(modalStyles.Title, styles.ForgotPasswordTitle)} data-sentry-element="Title" data-sentry-source-file="index.tsx">Reset your password</Title>
			<Description className={classNames(modalStyles.DescriptionPassword, styles.ForgotPasswordDescription)} data-sentry-element="Description" data-sentry-source-file="index.tsx">
				Enter the email linked to your Arkadium profile to receive a reset link​
			</Description>
			<Root className={classNames(formStyles.Root, styles.ForgotPasswordForm)} onSubmit={async event => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      forgotPasswordFormAction(data);
    }} data-sentry-element="Root" data-sentry-source-file="index.tsx">
				<Field className={classNames(formStyles.Field)} name='email' data-sentry-element="Field" data-sentry-source-file="index.tsx">
					<Label className={formStyles.Label} data-sentry-element="Label" data-sentry-source-file="index.tsx">Email</Label>
					<Control asChild data-sentry-element="Control" data-sentry-source-file="index.tsx">
						<input required defaultValue={email} className={classNames(formStyles.Input)} name='email' type='email' placeholder='your.email@mail.com' />
					</Control>
					<Message match='valueMissing' data-sentry-element="Message" data-sentry-source-file="index.tsx">
						<AuthError error='Please enter your email' data-sentry-element="AuthError" data-sentry-source-file="index.tsx" />
					</Message>
					<Message match='typeMismatch' data-sentry-element="Message" data-sentry-source-file="index.tsx">
						<AuthError error='Please provide a valid email' data-sentry-element="AuthError" data-sentry-source-file="index.tsx" />
					</Message>
					<AuthErrorList errors={errors} data-sentry-element="AuthErrorList" data-sentry-source-file="index.tsx" />
				</Field>
				<Submit asChild data-sentry-element="Submit" data-sentry-source-file="index.tsx">
					<ContinueButton loading={loading} text='Reset password' data-sentry-element="ContinueButton" data-sentry-source-file="index.tsx" />
				</Submit>
			</Root>
		</>;
};