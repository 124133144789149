'use client';

import React, { useState } from 'react';
import style from '@/components/Profile/Components/ProfileFields/style.module.css';
import { ProfileText } from '@/components/Profile/Components/ProfileText/ProfileText';
import classNames from 'classnames';
import Image from 'next/image';
import { ProfileError } from '@/components/Profile/Components/ProfileError/ProfileError';
import { EUsernameErrors } from '@/root/actions/profile/updateUserName.action';
import { fetchInternalRoute, INTERNAL_ROUTES } from '@/root/libs/api/data-fetcher';
import { ResponseError } from '@/root/types/ResponseError';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
export type ProfileUsernameProps = {
  name: string;
  mainStateUpdate: (data: string) => void;
};
export const Username = ({
  name,
  mainStateUpdate
}: ProfileUsernameProps) => {
  const [value, setValue] = useState<string>(name);
  const [error, setError] = useState<null | string>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const doUpdate = (ev: any) => {
    const value = ev.currentTarget.value;
    const error = checkUsernameErrors(value);
    if (!error) {
      setValue(value);
      setDisabled(true);
      fetchInternalRoute<UserProfileDTO | ResponseError>(INTERNAL_ROUTES.UPDATE_USERNAME, {
        method: 'PATCH',
        body: JSON.stringify({
          name: value
        })
      }).then(res => {
        const updatedProfile = res as UserProfileDTO;
        updatedProfile.name !== value && setValue(updatedProfile.name);
        mainStateUpdate(updatedProfile.name);
        setError(null);
      }).catch(error => {
        setError(error);
      }).finally(() => {
        setDisabled(false);
      });
    } else {
      setError(error);
    }
  };
  return <div className={style.FieldBlock} data-sentry-component="Username" data-sentry-source-file="Username.tsx">
			<label className={style.Field} data-testid='profile-username-field'>
				<ProfileText text={'Username'} isBold={true} addClasses={[style.FieldLabel]} data-sentry-element="ProfileText" data-sentry-source-file="Username.tsx" />
				<input name='name' type='text' placeholder='Your username' className={classNames(style.FieldInput)} value={value} disabled={disabled} onChange={(ev: any) => setValue(ev.currentTarget.value)} onBlur={doUpdate} style={{
        marginBottom: '0'
      }} />
				<div className={style.FieldInputIcon} style={{
        pointerEvents: 'none'
      }}>
					<Image className={style.FieldInputIconImage} src='/assets/arrows/pencil-01.svg' alt='input-icon' width={19.33} height={19.33} data-sentry-element="Image" data-sentry-source-file="Username.tsx" />
				</div>
			</label>
			<ProfileError error={error} data-sentry-element="ProfileError" data-sentry-source-file="Username.tsx" />
		</div>;
};
export function checkUsernameErrors(newVal: any): string | null {
  return newVal === '' ? EUsernameErrors.EMPTY : !/^[a-zA-Z][a-zA-Z0-9_-]{2,19}$/.test(newVal) ? EUsernameErrors.FORMAT : null;
}