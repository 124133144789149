import { Badge, ButtonOverride, Category } from '../api/arena-data';
import { buttonBackgroundCalculation } from '@/uikit/helper_funcs/buttonBackgroundCalculation';
import { ALL_CATEGORY, ALPHABETICAL_SORT_LABEL } from './constants';
import { ShortGameModelFromFeed } from '@/types/games';
import { captureMessage } from '@sentry/nextjs';

export const getBrowserCookie = (name: string): string | undefined => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop()?.split(';').shift();
};

export function capitalizeFirstLetter(value: string) {
	return value.charAt(0).toUpperCase() + value.slice(1);
}

export function buildButtonCSSOverrides(isDesktopDetected: boolean, overrides?: ButtonOverride[] | null) {
	const result: { [key: string]: string } = {};

	if (!overrides) {
		return result;
	}

	for (const override of overrides ?? []) {
		if (!isDesktopDetected && override.state === 'hover') {
			return {};
		}

		if (override.background) {
			const { bcgNormal } = buttonBackgroundCalculation({
				override_button_normal_background: override.background,
				override_button_normal_background_end: override.background_end,
			});
			result[`--${override.state}-background`] = bcgNormal;
		}
		if (override.border) {
			result[`--${override.state}-border`] = override.border;
		}
		if (override.color) {
			result[`--${override.state}-color`] = override.color;
		}
	}
	return result;
}

// Function to get badges for a specific game by slug
export function getValidBadgesBySlug(dataSrc: any[], slug: string, validBadges: Badge[]) {
	// Find the game in the array by its slug
	const game = dataSrc.find((game) => game.slug === slug);

	if (game?.meta?.badges) {
		const firstMatchingBadge = game.meta.badges.find((badgeLabel: string) => {
			return validBadges.some((badge) => badge.label.toLowerCase() === badgeLabel.toLowerCase());
		});

		// If a matching valid badge is found, return an object with label, bgColor and labelColor
		if (firstMatchingBadge) {
			const validBadge = validBadges.find(
				(badge) => badge.label.toLowerCase() === firstMatchingBadge.toLowerCase(),
			);
			if (validBadge) {
				return {
					label: validBadge.label,
					backgroundColor: validBadge.backgroundColor,
					labelColor: validBadge.labelColor,
				};
			}
		}
	}

	// If the game or its badges are not found, return an object with default values
	return { label: '', backgroundColor: '', labelColor: '' };
}

export function getGameAlias(game: any, gameFeedData: any): string | undefined {
	if (game) {
		const alias = gameFeedData?.find((feedGame: any) => feedGame.slug === game.slug)?.meta?.alias;
		return alias;
	} else {
		return undefined;
	}
}

export async function browserTrackingConsent() {
	// globalPrivacyControl supported only on Firefox 120+
	// navigator.doNotTrack (although deprecated on mdn) works for most browsers except Safari
	// checkIfCrossSiteCookiesBlocked dedicated solution for Safari but works for all browsers
	if (typeof window === 'undefined') return false;

	const isGlobalPrivacyEnabled = (navigator as any)?.globalPrivacyControl === true;
	const isDoNotTrackEnabled = navigator.doNotTrack === '1';
	const areCrossSiteCookiesAllowed = await checkIfCrossSiteCookiesBlocked();

	const hasConsent = !isGlobalPrivacyEnabled && !isDoNotTrackEnabled && areCrossSiteCookiesAllowed;

	return hasConsent;
}

// Safari doesn't have a browser API to check if cross site cookies are blocked, this method tests if they are blocked using an iframe
export function checkIfCrossSiteCookiesBlocked(): Promise<boolean> {
	if (typeof window === 'undefined') return Promise.resolve(false);

	const controller = new AbortController();
	const { signal } = controller;

	return new Promise((resolve) => {
		const frame = document.createElement('iframe') as any;
		frame.src = 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/evo-cookie-consent/index.html';
		frame.sandbox = 'allow-scripts allow-same-origin';
		frame.style.display = 'none';
		frame.onload = () => {
			if (signal.aborted) return;
			frame.contentWindow.postMessage(JSON.stringify({ test: 'cookie' }), '*');
		};

		const timeout = setTimeout(() => {
			controller.abort();
		}, 5000);

		const messageHandler = (event: any) => {
			if (signal.aborted) return;
			let data;
			try {
				data = JSON.parse(event.data);
			} catch (e) {
				return;
			}
			if (data['evoCheckCookiesEnable'] === undefined) return;
			cleanup();
			resolve(data['evoCheckCookiesEnable'] as boolean);
		};

		const cleanup = () => {
			window.removeEventListener('message', messageHandler);
			document.body.removeChild(frame);
			clearTimeout(timeout);
		};

		window.addEventListener('message', messageHandler);
		document.body.appendChild(frame);

		signal.addEventListener('abort', () => {
			captureMessage('Cross site cookie check timed out');
			cleanup();
			resolve(false);
		});
	});
}

export function sortFeed(by: string, feed: ShortGameModelFromFeed[]): ShortGameModelFromFeed[] {
	// Helper function to normalize strings (case-insensitive)
	const normalizeString = (value: string) => value?.toLowerCase().trim();

	// Normalize the "by" badge
	const normalizedBy = normalizeString(by);

	// Separate items into those with and without the matching badge
	const withBadge: ShortGameModelFromFeed[] = [];
	const withoutBadge: ShortGameModelFromFeed[] = [];

	feed.forEach((item) => {
		const badges = item?.meta?.badges?.map(normalizeString) || [];
		if (badges.includes(normalizedBy)) {
			withBadge.push(item);
		} else {
			withoutBadge.push(item);
		}
	});

	// Sort the items with the badge by their badge position and alphabetically if needed
	withBadge.sort((a, b) => a.name.localeCompare(b.name));

	// Sort the items without the badge alphabetically
	withoutBadge.sort((a, b) => a.name.localeCompare(b.name));

	// Merge the sorted arrays: items with badges come first
	return [...withBadge, ...withoutBadge];
}

export function createSortOptions(sort: string, badges: any[], sortedFeed: ShortGameModelFromFeed[]): string[] {
	// Normalize function to lowercase for consistency in checking duplicates
	const normalize = (str: string) => str.toLowerCase().trim();

	// Capitalization function
	const capitalize = (str: string) => {
		if (!str) return str;
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	// Extract unique badge labels and normalize them
	const uniqueBadges = new Set(
		badges
			.flatMap((b: { label?: string }) => b?.label || '')
			.filter((badgeLabel) => {
				// Filter only badges that exist in sortedFeed
				const normalizedBadge = normalize(badgeLabel);
				return sortedFeed.some((game) =>
					game.meta.badges?.some((badge) => normalize(badge) === normalizedBadge),
				);
			}),
	);

	// Normalize the `sort` and `ALPHABETICAL_SORT_LABEL` labels
	const normalizedSort = normalize(sort);
	const normalizedAlphabetical = normalize(ALPHABETICAL_SORT_LABEL);

	// Create the initial options array, normalizing everything
	const options = [
		normalizedSort,
		...(normalizedSort !== normalizedAlphabetical ? [normalizedAlphabetical] : []),
		...Array.from(uniqueBadges).sort(),
	];

	// Remove duplicates by converting to a Set and back to an array, ensuring everything is lowercase
	const uniqueOptions = [...new Set(options.map(normalize))];

	// Capitalize the final array and return
	return uniqueOptions.map(capitalize);
}

export const extractCategoriesFromArena = (arenaCategories: Category[]) => {
	const categorySet = new Set<string>();
	arenaCategories.forEach((category) => {
		if (category.slug && category.slug !== 'all') {
			categorySet.add(category.slug.toLowerCase().trim());
		}
	});
	return [ALL_CATEGORY.name?.toLowerCase() ?? '', ...categorySet];
};

export const normalizestring = (category: string) => {
	if (category === null) return '';
	return category?.toLowerCase().replaceAll('-', ' ').trim();
};

export const filterFeedByCategory = (
	feed: ShortGameModelFromFeed[],
	category: string | undefined,
	arenaCategories?: { slug: string }[],
) => {
	const validSlugs = arenaCategories
		? new Set(arenaCategories.map((category) => normalizestring(category.slug)))
		: null;

	return category
		? feed.filter((g) =>
				g?.meta?.categories?.some((c: any) => {
					return normalizestring(c?.name || c) === normalizestring(category);
				}),
			)
		: feed.filter((g) =>
				g?.meta?.categories?.some((c: any) => {
					return validSlugs?.has(normalizestring(c?.name || c));
				}),
			);
};

export const getUniqueCategories = (games: ShortGameModelFromFeed[], arenaCategories: Category[]): string[] => {
	// Extract normalized slugs from arenaCategories for ordering
	const arenaCategoryOrder = arenaCategories.map((category) => normalizestring(category.slug));

	// Extract and normalize unique categories from games
	const gameCategoryNames = Array.from(
		new Set(games.flatMap((game) => game.meta?.categories).map((category: any) => normalizestring(category?.name))),
	);

	// Reorder game categories to match arenaCategoryOrder
	const orderedCategories = arenaCategoryOrder.filter((slug) => gameCategoryNames.includes(slug));

	// Return the final list with 'see all' at the beginning
	return ['see all', ...orderedCategories];
};

export const getPageSlug = (pathname: string) => pathname.split('/').slice(2).join('/');
export const isObjectEmpty = (obj: any) => obj && Object.keys(obj).length === 0;
